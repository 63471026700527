.pageContent {
  background: #fff;
  border-radius: 10px;
}
.pageTitle {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0;
}

.tableFooter {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.header {
  display: flex;
  justify-content: space-between;
  padding: 12px 20px;
}

.pageContent :global(.ant-table-tbody > tr.ant-table-row:hover) .actionsColumn {
  opacity: 1;
}

.filterBtns {
  display: flex;
  justify-content: flex-end;
  width: 620px;
  flex-wrap: wrap;
}

.filterBtn {
  margin: 0 0 10px 10px;
}

.header .navigationBtn {
  background: #fff;
  color: #de7f39;
  border-color: #de7f39;
  height: auto;
  display: flex;
  align-items: center;
  border-radius: 6px;
}

.export {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 174px;
  margin-top: 20px;
}
.pagination {
  margin-top: 20px;
}

.gapForPagination {
  margin-right: 120px;
}
.export .exportBtn {
  width: 130px;
  height: 40px;
}
.tooltipInfo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #de7f39;
}

.tooltipDisabled {
  background: #d9d9d9;
}

.export .infoIcon {
  color: #fff;
  font-weight: bold;
}
.pageContent :global(.ant-table-expanded-row-fixed) {
  padding: 0 !important;
  padding: 16px 8px !important;
  margin: 0 !important;
  width: auto !important;
}

@media screen and (max-width: 812px) {
  .pagination {
    margin-top: 30px;
  }
}
