.modalContent,
.recurringModalContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 500px;
  margin: 0 auto;
}
.modalDescription {
  text-align: center;
  margin: 0 auto 50px;
}
.modalBtns,
.recurringModalBtns {
  display: flex;
  justify-content: space-around;
  padding: 0px 100px;
}
.modalBtns .navigationBtn,
.recurringModalBtns .cancelBtn {
  background: #fff;
  color: #de7f39;
  border-color: #de7f39;
}
.recurringModalBtns .savebtn {
  background: #de7f39;
  color: #fff;
  border-color: #de7f39;
}
.recurringModalOptions > div {
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
}
.recurringModalOptions > div > label {
  font-size: 14px;
  line-height: 22px;
  color: #595959;
  padding-bottom: 18px;
  align-self: flex-start;
}
.recurringModalOptions > div > label > span:last-child {
  padding-left: 12px;
}
.formButton {
  margin-right: 10px;
}
@media screen and (max-width: 812px) {
  .modalContent,
  .recurringModalContent {
    width: auto;
    padding: 0 20px;
  }
}
