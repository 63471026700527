.driversBtns {
  max-width: 812px;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  box-shadow: 0px -3px 25px rgba(217, 172, 139, 0.12);
  background: #fff;
  padding: 22px 41px;
}
.driversBtns .cancelRideBtn {
  margin-right: 30px;
  background: #fff;
  color: #de7f39;
}

.driversBtns .arrivalBtn {
  background: #1cb200 !important;
  border-color: #1cb200 !important;
}

@media (max-width: 872px) {
  .driversBtns {
    padding: 22px 70px 22px 10px;
  }
}
