.btn.customBtn {
  width: 100%;
  border-radius: 8px;
  height: 100%;
  background: #de7f39;
  color: #fff;
  padding: 7px 15px;
  font-size: 16px;
  line-height: 24px;
}

.btn :global(.ant-click-animating-without-extra-node:after) {
  -webkit-animation: none !important;
  -moz-animation: none !important;
  -o-animation: none !important;
  -ms-animation: none !important;
  animation: none !important;
}

.btn.customBtn:focus,
.btn.customBtn:active {
  background: #fff;
  color: #de7f39;
  border-color: #de7f39;
}

.btn.customBtn:hover {
  opacity: 0.9;
  background: #de7f39 !important;
  color: #fff !important;
  border-color: #de7f39 !important;
}

.btn.customBtn:disabled {
  background: #f5f5f5;
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
}
