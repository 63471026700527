.modalContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 420px;
  margin: 0 auto;
}
.modalDescription {
  text-align: center;
  margin: 0 auto 20px;
}

.modalBtns {
  display: flex;
  width: 420px;
  margin: 0 auto;
  justify-content: space-between;
}

.modalBtns .navigationBtn {
  background: #fff;
  color: #de7f39;
  border-color: #de7f39;
}
