.drawerMask {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  background: rgb(66, 65, 65);
  transition: opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.drawerMaskOpen {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  background: rgb(66, 65, 65);
  opacity: 0.3;
  height: 100%;
  transition: opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
