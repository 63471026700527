.dropdownContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: auto;
  max-height: 500px;
}
.checkbox {
  margin-bottom: 16px;
  display: flex;
  cursor: pointer;
}
.checkbox:last-of-type {
  margin-bottom: 0;
}
.statusCheckbox {
  width: 150px;
}

.checkboxLabel {
  margin-left: 8px;
}
