.routes {
  display: block;
  margin-left: 36px;
  margin-right: 36px;
  margin-bottom: 15px;
}

.roundTripHeading {
  text-align: center;
  font-size: 14px;
}

.destionationRouteInfo {
  margin-top: 20px;
  align-self: flex-start;
  flex-direction: row;
}
.align_center {
  text-align: center;
}
.modalContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 400px;
  margin: 0 auto;
}
.modalDescription {
  margin: 0 auto 25px;
}
.modalSecondLine {
  display: block;
  margin-top: 20px;
}
.modalBtns {
  display: flex;
  justify-content: space-around;
}
.modalBtns .navigationBtn {
  background: #fff;
  color: #de7f39;
  border-color: #de7f39;
}
.optionName {
  font-weight: bold;
}

.modal :global(.ant-modal-content) {
  border-radius: 10px;
  padding: 60px 0;
}

:global(.ant-modal-title) {
  font-size: 24px;
  line-height: 24px;
  color: #4f4f60;
  padding-left: 40px;
  padding-right: 40px;
}

@media screen and (max-width: 812px) {
  .modalContent {
    width: auto;
    padding: 0 20px;
  }
}
