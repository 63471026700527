.input.customInput {
  padding: 7px 11px;
  outline: none;
  border-color: #de7f39;
  box-shadow: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  color: #333;
  line-height: 24px;
  resize: none;
}

.input.customInput:active,
.input.customInput:focus,
.input.customInput:hover,
.input.customInput::selection,
.input.customInput:global(
    .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled)
  ):hover {
  outline: none;
  border-color: #de7f39;
  box-shadow: 0 0 0 1px rgb(214 178 79 / 50%);
}
.input.customInput :global(.ant-input-suffix .ant-input-clear-icon) {
  line-height: 14px;
  font-size: 14px;
}

.input.input.customInput:global(.ant-input-affix-wrapper-focused) {
  outline: none;
  border-color: #de7f39;
  box-shadow: 0 0 0 1px rgb(214 178 79 / 50%);
}

.input:global(.ant-input-affix-wrapper > input.ant-input) {
  outline: none;
  border-color: #de7f39;
  box-shadow: none;
}

.input.customInput:global(.ant-input[disabled]),
.input.customInput:global(.ant-input-affix-wrapper-disabled) {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}

.input.customInput:global(.ant-input[disabled]):hover,
.input.customInput:global(.ant-input-affix-wrapper-disabled):hover {
  outline: none;
  border-color: #d9d9d9;
  box-shadow: 0 0 0 1px rgb(214 178 79 / 50%);
}

.errorMessage {
  color: #f5222d;
  font-size: 12px;
  line-height: 22px;
}
.input.customInput.error {
  border-color: #f5222d;
}
.height {
  margin-right: 20px;
  display: flex;
  justify-content: space-between;
  width: 270px;
}

.required:after {
  content: " *";
  color: red;
}

.measurement {
  font-size: 12px !important;
}

.input_height {
  display: block !important;
}
.timeControl {
  display: block;
  width: 120px;
}
