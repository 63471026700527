.riderInfo {
  width: 630px;
}

.checkboxLabel {
  margin-left: 8px;
}
.textCheckbox {
  margin-right: 24px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.caregiverCheckbox {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.emailInfo {
  display: block;
  width: 300px;
  margin-bottom: 14px;
  height: 84px;
}

.rideSelection {
  display: block;
  width: 300px;
  margin-bottom: 20px;
  height: 84px;
}
.ridesNameInfo,
.searchRider,
.caregiverContactInfo {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.searchRider {
  margin-bottom: 30px;
}
.ridesName,
.careGiverName {
  height: 84px;
  width: 300px;
  margin-bottom: 20px;
}
.autocompleteField {
  width: 300px;
}
.autocompleteField .autoComplete {
  margin-right: 10px;
}
.switch {
  display: flex;
  margin: 10px 0 20px;
}

.switchTitle {
  margin-right: 10px;
}
.tooltipInfo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #de7f39;
}
.riderInfo .infoIcon {
  color: #fff;
  font-weight: bold;
}
.tooltipContent {
  display: block;
  padding: 4px;
  
}
.riderSwitch:global(.ant-switch-checked) {
  background: #de7f39;
}
.phoneInfo {
  display: block;
  width: 300px;
  height: 84px;
}
.phoneInput {
  width: 300px;
}
.phoneCheckboxes {
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0;
}
.autocompleteWrapper {
  display: flex;
}

.headTitle {
  margin: 0 0 50px 0;
  font-size: 24px;
}
.careGiverInfo {
  display: block;
  margin: 0 0 20px 0;
}
.caregiverTitle {
  margin: 40px 0 40px 0;
}
.caregiverSubTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 125px;
}

.caregiverSubTitle:hover .removeIcon {
  opacity: 1;
  cursor: pointer;
  transition: all 0.3s;
}

.caregiverSequencyNumber {
  margin: 0;
}
.caregiverSubTitle .removeIcon {
  opacity: 0;
  font-size: 12px;
  color: grey;
  transition: all 0.3s;
}
.careGiverInfo::before {
  content: '';
  display: block;
  height: 1px;
  background: #f2f2f2;
  margin: 0 0 40px 0;
}

.required:after {
  content: ' *';
  color: red;
}

.errorMessage {
  color: #f5222d;
  font-size: 12px;
  line-height: 22px;
}

.btns .formControlBtn {
  background: #fff;
  color: #de7f39;
  border-color: #de7f39;
  width: auto;
}
.addCaregiver .addCaregiverBtn {
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: dashed #de7f39 1px;
  background: transparent;
  color: #de7f39;
  margin-top: 40px;
}

@media screen and (max-width: 975px) {
  .riderInfo {
    width: 100%;
  }
}
