.container {
    border-radius: 4px;
    border: 1px solid #e0e0e0;
    padding: 12px;
    background: rgb(251 251 251);
    margin: 0px 0px 20px 0;
}

.tag {
    cursor: move;
    transition: unset;
    padding-bottom: 2px;
}

.closeIcon {
    font-size: 16px !important; 
    padding-left: 4px !important;
}

.container :global(.drag-drop-list-container) {
    display: flex;
}
.flex
{
    display: flex;
    flex-wrap: wrap;
    gap:"4px 0";
    
   
}

.container :global(.ant-tag-checkable:not(.ant-tag-checkable-checked):hover) {
    color: rgba(0, 0, 0, 0.88);   
}

:global(.ant-popover-content .ant-btn-primary ){
    background-color: #de7f39;
}

:global(.ant-popover-content .ant-btn-primary:hover ){
    background-color: #de7f39;
}

:global(.ant-popover-content .ant-btn-default:not(:disabled):hover ){
    color: #de7f39;
    border-color: #de7f39;
}

