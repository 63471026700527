.drawerHandle {
  display: none;
}

.drawerHandleIcon {
  width: 16px;
  height: 2px;
  background: #fff;
  position: relative;
  -webkit-transition: background 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: background 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  text-align: center;
  line-height: 40px;
  font-size: 16px;
  cursor: pointer;
}

.drawerHandleIcon:after,
.drawerHandleIcon:before {
  content: "";
  display: block;
  position: absolute;
  background: #fff;
  width: 100%;
  height: 2px;
  -webkit-transition: -webkit-transform 0.3s
    cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: -webkit-transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86),
    -webkit-transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.drawerHandleIcon:before {
  top: -5px;
}

.drawerHandleIcon:after {
  top: 5px;
}

.drawerOpen {
  left: 200px;
}

.drawerOpen .drawerHandleIcon {
  background: 0 0;
}

.drawerOpen .drawerHandleIcon:before {
  transform: translateY(5px) rotate(45deg);
}

.drawerOpen .drawerHandleIcon:after {
  transform: translateY(-5px) rotate(-45deg);
}

@media screen and (max-width: 812px) {
  .drawerHandle {
    display: flex;
    box-shadow: 1px 0 2px rgba(0, 0, 0, 0.15);
    border-radius: 0 4px 4px 0;
    position: fixed;
    top: 130px;
    transition: all 0.2s;
    left: 0;
    width: 41px;
    height: 40px;
    cursor: pointer;
    z-index: 2;
    text-align: center;
    line-height: 40px;
    font-size: 16px;
    display: -webkit-box;
    display: -ms-flexbox;
    justify-content: center;
    align-items: center;
    background: #de7f39;
    transition: all 0.2s;
  }
  .drawerOpen {
    left: 199px;
  }
}
