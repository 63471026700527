.modalContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 400px;
  margin: 0 auto;
}
.modalDescription {
  text-align: center;
  margin: 0 auto 50px;
}
.modalBtns {
  width: 280px;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
}
.modalBtns .navigationBtn {
  background: #fff;
  color: #de7f39;
  border-color: #de7f39;
}
