.pageContent {
  background: #fff;
  border-radius: 10px;
  min-height: 100vh;
}

.pageTitle {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 12px 30px 12px 20px;
}
.pageTitle:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: '\2026';
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 20px;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 20px;
  }
}
