.actionsButtons {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  left: auto;
  right: 47px;
  height: 190px;
  width: auto;
  position: fixed;
  bottom: 80px;
}

.twoActionsButtons {
  height: 130px;
}
.singleButton {
  justify-content: flex-end;
}

.actionsButtons .defaultIcon,
.actionsButtons .actionsIcon {
  font-size: 22px;
  display: inline-block;
}

.actionsButtons .defaultIcon {
  color: rgba(0, 0, 0, 0.25);
}

.actionsButtons .actionsIcon {
  color: #fff;
}
.actionsButtons .defaultIcon:hover {
  cursor: not-allowed;
  opacity: 1;
}

.action {
  border-radius: 50%;
  background: #de7f39;
  color: #fff;
  padding: 14px;
  height: 54px;
  width: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.actionsButtons .action:hover {
  opacity: 0.7;
  cursor: pointer;
}

.actionDisabled {
  background: #f5f5f5;
}

.actionsButtons .actionDisabled:hover {
  cursor: not-allowed;
}
