.setPasswordPage {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: center;
  padding: 0 20px;
}
.head {
  width: 495px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 120px;
}
.pageHeader {
  margin: 0;
  font-size: 24px;
  line-height: 24px;
  color: #4f4f60;
}
.pageDescription {
  margin: 20px 0 40px 0;
  width: 368px;
  font-size: 14px;
  line-height: 24px;
  font-weight: normal;
  text-align: center;
  color: #4f4f60;
}
.passwordInput {
  display: block;
  margin: 30px 0;
}
.resetForm {
  width: 370px;
  margin-bottom: 30px;
}
.resetForm .input {
  padding: 7px 11px;
  box-shadow: none;
  border: 1px solid #d9d9d9;
}
.button {
  width: 266px;
  height: 40px;
  margin: 30px auto 0 auto;
  width: auto;
}
.setPasswordPage .btn {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  padding: 0 3px 0 0;
  border-color: #e0e0e0;
  margin-right: auto;
}
.required:after {
  content: ' *';
  color: red;
}
@media screen and (max-width: 812px) {
  .resetForm {
    width: 100%;
    padding: 0 10px;
  }
}
