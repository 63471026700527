.cancelRidePage {
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;
}
.pageTitle {
  display: flex;
  margin: 0 auto;
  width: 300px;
}
.pageDescription {
  margin: 100px 0 40px 0;
  font-size: 14px;
  line-height: 24px;
  font-weight: normal;
  text-align: center;
  color: #4f4f60;
}
.formContainer {
  width: 470px;
  margin: 0 auto;
}
.form {
  margin-bottom: 40px;
}
.cancelRideLabel {
  display: block;
  width: 300px;
  margin: 0 auto 16px auto;
}
.modalBtns {
  width: 360px;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
}
.required:after {
  content: ' *';
  color: red;
}

@media screen and (max-width: 812px) {
  .formContainer, .cancelRidePage {
    width: auto;
  }
}
