.stepper :global(.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon) {
  background: #de7f39;
  border-color: #de7f39;
}

.stepper :global(.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon),
.stepper :global(.ant-steps-item-finish .ant-steps-item-icon) {
  color: #de7f39;
  border-color: #de7f39;
  background-color: transparent;
}
.stepper :global(.ant-steps-item-active .ant-steps-item-icon > .ant-steps-icon) {
  color: #fff;
}
.stepper:global(.ant-steps
    .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)
    > .ant-steps-item-container[role='button']:hover
    .ant-steps-item-icon),
.stepper:global(.ant-steps
    .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)
    > .ant-steps-item-container[role='button']:hover
    .ant-steps-item-icon
    .ant-steps-icon) {
  color: #de7f39;
  border-color: #de7f39;
  opacity: 0.8;
}

.stepper:global(.ant-steps
    .ant-steps-item:not(.ant-steps-item-active)
    > .ant-steps-item-container[role='button']:hover
    .ant-steps-item-title) {
  color: rgba(0, 0, 0, 0.85);
  opacity: 0.8;
}

.stepper :global(.ant-steps-item.ant-steps-item-finish .ant-steps-icon),
.stepper :global(.ant-steps-item.ant-steps-item-error .ant-steps-icon) {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.stepper :global(.ant-steps-item.ant-steps-item-error .ant-steps-icon) {
  margin-top: 8px;
}

.stepper
  :global(.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after) {
  background: #de7f39;
}

.stepper :global(.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after) {
  background: #de7f39;
}

.stepper :global(.ant-steps-item-title) {
  font-size: 14px;
  line-height: 28px;
}
