.receiptsContainer {
    font-family: Arial, sans-serif;
    margin: 10px 0 30px 0;
    display: flex;
    padding: 20px 0;
    flex-wrap: wrap;
    align-content: center;
}

.rideReceiptContainer {
    padding: 20px;
    min-width: 370px;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    margin: 20px 20px 0 0;
  }

.rideReceiptHeader {
    display: flex;
    flex-direction: row;
}


.flexWithColumnDir {
    display: flex;
    flex-direction: column;
}

.headerCol2 {
    width: 35%; 
    align-items: self-end;
}

.descriptionCol2 {
    width: 30%; 
    align-items: self-end;
}

.rideReceiptHdrCol {
    width: 50%;
}

.rideReceiptLabel {
    margin-bottom: 2px;
    color: rgb(130 126 126);
}

.rideReceiptIndex {
    font-size: 60px; 
    font-weight: 600; 
    border: 12px solid floralwhite;
    border-radius: 60%;
}

.rideReceiptDescTitle {
    font-weight: 600; 
    font-size: 15px; 
    margin-bottom: 16px; 
}

.rideReceiptDescCol1 {
    display: flex;
    flex-direction: column;
    width: 70%;
}

.rideReceiptContainer  p > span {
    float: right;
}

.rideReceiptDivider {
    border-top: 1px dotted #bbb; 
    margin-top: 24px;
}
.billSubTotal {
    font-weight: 600; 
    font-size: 16px;
}
.billSaleTax  {
    font-weight: 600; 
    font-size: 17px;
}
.billTotal  {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: x-large;
}
.billTotalValue  {
    font-weight: 600; 
    font-size: xx-large;
    margin-left: auto;
    order: 2;
}
.widthPercent65 {
    width: 65%;
}

.widthPercent70 {
    width: 70%;
}
.mb28 {
    margin-bottom: 28px;
}