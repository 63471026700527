.tableWrapper :global(.ant-table-thead > tr > th),
.tableWrapper :global(.ant-table-tbody > tr > td),
.tableWrapper :global(.ant-table tfoot > tr > th),
.tableWrapper :global(.ant-table tfoot > tr > td) {
  padding: 16px 12px;
  height: 55px;
  color: #4f4f60;
}
.ridersTable :global(.ant-table-tbody > tr.ant-table-row:hover > td) {
  background: #fff8f2;
  cursor: pointer;
}

.btnPanel {
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  width: 380px;
}

.btnPanel .navigationBtn {
  background: #fff;
  color: #de7f39;
  border-color: #de7f39;
}

.btnPanel .restoreAccess {
  margin-left: auto;
  margin-right: 14px;
}

.tableButton {
  margin-right: 30px;
}
.tableButton:last-of-type {
  margin-right: 14px;
}

.cellValue {
  word-break: break-word;
}

@media screen and (max-width: 812px) {
  .cellValue {
    word-break: inherit;
  }
}
