.modalContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
}
.modalDescription {
  text-align: center;
  
}
.modalBtns {
  width: 360px;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  margin-top: 40px;
}
.modalBtns .navigationBtn {
  background: #fff;
  color: #de7f39;
  border-color: #de7f39;
}
.cancelRideBtn .customBtn {
  background: #fff;
  color: #de7f39;
  border-color: #de7f39;
}
.modalTitle {
  font-size: 23px;
}
.form {
  width: 70%;
  margin: 0 auto;
}
.comment {
  display: block;
  width: auto;
  margin: 20px 20px 30px;
}
.required:after {
  content: ' *';
  color: red;
}

.errorMessage {
  color: #f5222d;
  font-size: 12px;
  line-height: 22px;
}
