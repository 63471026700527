.headTitle {
  margin: 0 0 50px 0;
  font-size: 24px;
}

.editingHeadTitle {
  font-size: 20px;
  line-height: 24px;
}

.form {
  width: 780px;
}

.address {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.subHeadings {
  font-weight: 700;
  font-size: 16px;
  display: block;
  margin-bottom: 32px;
}
.securityInstruction
{
  width: 100%;
  display: block;
  margin: 0 0 30px 0;
}
.building {
  display: block;
  width: 150px;
  margin: 40px 40px 0 0;
}
.rescheduleInputLabel
{
  margin-top: 10px;
  font-weight: 500;
  font-size: 16px;
  
}

.checkboxLabelText {
  margin-left: 8px;
}

.buildingAdditionalInfo {
  width: 300px;
  margin-bottom: 40px;
}

.apartment {
  display: block;
  width: 140px;
  margin: 40px 40px 0 0;
}

.midnightNoonMessage {
  position: absolute;
  margin-left: 10px;
}

.apartmentCheckbox {
  display: inline-flex;
  margin: 30px 0;
}

.locationDescription {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
}
.appointmentDate {
  margin-left: 10px  ;
  margin-top: 8px;
}
.timezoneSection {
  display: flex;
}
.timeZoneTooltip {
  margin-left: 14px  ;
  margin-top: 8px;
}
.tooltipInfo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #de7f39;
}
 .infoIcon {
  color: #fff;
  font-weight: bold;
}
.tooltipContent {
  display: block;
  padding: 4px;
}

.routes {
  display: flex;
  margin: 10px 0 30px 0;
}

.destionationRouteInfo {
  display: flex;
  align-self: flex-start;
}
.roundTripHeader
{
  font-size: 1em;
  margin-top: 10px;
}

.arrows {
  width: 97px;
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.align_center {
  text-align: center;
}

 
.appointmentWrapper {
  display: flex;
}

.destinationAddress {
  display: block;
}

.dateAndTime {
  display: flex;
  flex-wrap: wrap;
}

.dateAndTime .datepicker {
  margin: 0 30px 0 0;
  border-radius: 4px;
  width: 300px;
}

.timePicker {
  width: 170px;
  margin-bottom: 20px;
}

.nestedDatepicker {
  margin-bottom: 0;
}

.roundtrip,
.recurring {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 20px;
}

.roundtripChecked {
  margin-bottom: 20px;
}

.recurring {
  margin-top: 20px;
}

.recurringInfo {
  display: flex;
  margin-bottom: 40px;
}

.repeats {
  margin-top: 20px;
  width: 300px;
}

.repeats:first-child {
  margin-right: 20px;
}

.roundtrip .icon,
.recurring .icon {
  color: #de7f39;
  margin-left: 8px;
}

.isRoundTripRide {
  margin-top: 20px;
}

.timezone {
  display: block;
  width: 160px;
  margin-bottom: 20px;
  margin-right: 20px;
}

.errorMessage {
  color: #f5222d;
  font-size: 12px;
  line-height: 22px;
}

.required:after {
  content: ' *';
  color: red;
}

.snackbarField {
  margin: 30px 0 20px 0;
}
.rescheduleFormContent
{
  width: 300px;
  display: flex;
  flex-direction: column;
  gap: 25px;
}
.heading

.formContent .addressLabel .error {
  border-color: #f5222d;
}

.formContent .googleAutoComplete {
  width: 100%;
}

.statPointOfRecurring {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}

.useDefaultAddress {
  display: flex;
  margin: 10px 0;
}

.facilityName {
  display: block;
  margin-top: 20px;
}

.useWindowOfTime {
  display: inline-flex;
}

.windowOfTime {
  display: flex;
  align-items: center;
  margin: 0 0 20px;
  width: 240px;
  justify-content: space-between;
}
.windowOfAppointmentTime {
  display: flex;
  align-items: center;
  margin: 0 0 20px;
  width: 400px;
  justify-content: space-between;
}

.range {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.dateAndTime .startDate {
  margin: 0 20px 20px 0;
  width: 300px;
}

.apartmentCheckbox {
  display: inline-flex;
  margin: 30px 0;
}

.securityCheckbox {
  display: block;
  margin: 0 0 30px 0;
}

.dateAndTime .endDate {
  width: 300px;
}

.tooltipInfo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #de7f39;
}

.tooltipGap {
  margin-left: 15px;
}

.form .infoIcon {
  color: #fff;
  font-weight: bold;
}

.otherInformation {
  width: 100%;
  margin-bottom: 20px;
}

@media screen and (max-width: 1075px) {
  .form {
    width: 100%;
  }
}

@media screen and (max-width: 812) {
  .range {
    width: 340px;
  }

  .dateAndTime .windowOfTime {
    width: 300px;
  }
}