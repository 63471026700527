.pagination :global(.ant-pagination-item.ant-pagination-item-active) {
  border-color: #de7f39;
  color: #de7f39;
  border-radius: 0px !important;
}
.pagination :global(.ant-pagination-item) {
  border-color: #fff4ec;
  color: #de7f39;
  border-radius: 0px !important;
}

.pagination :global(.ant-pagination-item:focus a),
.pagination :global(.ant-pagination-item:hover a) {
  color: #de7f39;
}

.pagination :global(.ant-pagination-item:focus),
.pagination :global(.ant-pagination-item:hover) {
  border-color: #de7f39;
  opacity: 0.6;
    border-radius: 0px !important;
}

.pagination :global(.ant-pagination-item a) {
  color: #de7f39;
  border-radius: 0px !important;
}

.pagination :global(.ant-pagination-next .ant-pagination-item-link),
.pagination :global(.ant-pagination-prev .ant-pagination-item-link) {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #de7f39;
  border-color: #fff4ec;
  border-radius: 0px !important;
}

.pagination :global(.ant-pagination-next:hover .ant-pagination-item-link),
.pagination :global(.ant-pagination-next:focus .ant-pagination-item-link),
.pagination :global(.ant-pagination-prev:hover .ant-pagination-item-link),
.pagination :global(.ant-pagination-prev:focus .ant-pagination-item-link) {
  color: #de7f39;
  border-color: #de7f39;
}

.pagination :global(.ant-pagination-disabled.ant-pagination-next .ant-pagination-item-link),
.pagination :global(.ant-pagination-disabled.ant-pagination-prev .ant-pagination-item-link) {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.pagination :global(.ant-pagination-options) {
  display: none;
}
.pagination :global(.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon),
.pagination :global(.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon) {
  color: #de7f39;
}
