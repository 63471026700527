.pageContent {
  background: #fff;
  border-radius: 10px;
}
.pageTitle {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 12px 30px 12px 20px;
}
.title {
  font-size: 24px;
  line-height: 24px;
  margin: 12px 40px 12px 0;
}
.pagination {
  display: flex;
  justify-content: flex-end;
  margin: 20px 0 60px;
}
.pageContent :global(.ant-table-tbody > tr.ant-table-row:hover) .actionsColumn {
  opacity: 1;
}
