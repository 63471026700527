.select.customSelect {
  width: 100%;
  height: 40px;
  display: block;
}

.select:global(.ant-select:not(.ant-select-customize-input) .ant-select-selector) {
  display: flex;
  height: 40px;
  padding: 4px 11px;
  border-radius: 4px;
}

.allowClear:global(.ant-select:not(.ant-select-customize-input) .ant-select-selector) {
  align-items: center;
}

.dropdown
  :global(.ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state) {
  color: #de7f39;
}

.select:global(.ant-select-multiple .ant-select-selection-item-remove) {
  display: flex;
  align-self: center;
}

.dropdown :global(.ant-select-item-option-content) {
  white-space: normal;
}

.select:global(.ant-select-multiple .ant-select-selection-search) {
  -webkit-margin-start: 0;
  margin-inline-start: 0;
}

.select:global(.ant-select:not(.ant-select-disabled):hover .ant-select-selector),
.select:global(.ant-select:not(.ant-select-disabled):focus .ant-select-selector),
.select:global(.ant-select:not(.ant-select-disabled):active .ant-select-selector),
.select:global(.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector) {
  border-color: #de7f39;
  box-shadow: 0 0 0 1px rgb(214 178 79 / 50%);
}

.dropdown :global(.ant-select-item-option-selected:not(.ant-select-item-option-disabled)) {
  background-color: #de7f3942;
}
.errorMessage {
  color: #f5222d;
  font-size: 12px;
  line-height: 22px;
}
.select.customSelect.error :global(.ant-select-selector) {
  border: 1px solid red;
}

.select:global(.ant-select-disabled) {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}

.select:global(.ant-select-disabled):hover {
  outline: none;
  border-color: #de7f39;
  box-shadow: 0 0 0 1px rgb(214 178 79 / 50%);
  border-radius: 4px;
}

.select:global(.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input) {
  height: 100%;
}

.select :global(.ant-select-clear) {
  display: flex;
  align-items: center;
  font-size: 14px;
  height: 14px;
  width: 14px;
}
