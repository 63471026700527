.sendDescription {
  margin: 60px 0 0;
  font-size: 14px;
  color: #828282;
}
.headTitle {
  margin-bottom: 40px;
}
.summary {
  font-size: 14px;
  margin-bottom: 60px;
  width: 500px;
}
.btns {
  margin-top: 16px;
  margin-bottom: 24px;
}
