.dropdownContent {
  display: flex;
  flex-direction: column;
}
.dropdownList {
  list-style: none;
  margin: 0;
  padding: 0;
}
.dropdownListItem {
  display: flex;
  align-items: center;
  background: #fff;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
  padding: 9px 12px;
}
.dropdownListItem:hover {
  background: #fff8f2;
}
.dropdownListItem .calendarIcon {
  color: rgba(0, 0, 0, 0.25);
  margin-left: 8px;
}
.datepickerFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #bfbfbf;
}

.datepickerFooter .btn.footerBtn {
  width: 50px;
  padding: 5px;
  font-size: 14px;
  line-height: 16px;
}
.styledRangePicker {
  visibility: hidden;
  position: absolute;
  top: -25px;
  left: -2px;
  padding: 0 !important;
}
.dropdownList .calendarIcon {
  color: rgba(0, 0, 0, 0.25);
  margin-left: 8px;
}
