.pageContent {
  background: #fefdfc;
  border-radius: 10px;
  padding: 40px 0;
  width: 100%;
  max-width: 812px;
  margin: 0 auto;
  min-height: 100vh;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 72px;
}

.tabs {
  width: 100%;
}

.tabs :global(.ant-tabs-nav-list) {
  width: 100%;
}

.tabs :global(.ant-tabs-tab) {
  display: flex;
  justify-content: center;
  padding: 9px 16px;
}
.tabs :global(.ant-tabs-tab:hover) {
  color: #000;
}

.tabs :global(.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn) {
  color: #de7f39;
  font-size: 14px;
}
.pastTabDescription {
  margin: 0 0 0 16px;
  font-size: 14px;
  font-style: italic;
  color: #ababab;
}

.spin {
  display: flex;
  justify-content: center;
  align-items: center;
}
.spin :global(.ant-spin-dot-item) {
  background: #de7f39;
  opacity: 1;
}

@media screen and (max-width: 400px) {
  .pageContent {
    width: 350px;
  }
  .logOut {
    margin: 20px auto;
    width: 200px;
  }
}
