.pageContent {
  background: #fff;
  border-radius: 10px;
}
.pageTitle {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 12px 30px 12px 20px;
}
.title {
  font-size: 24px;
  line-height: 24px;
  margin: 12px 40px 12px 0;
}
.inputField {
  width: 256px;
  margin: 12px 0;
  margin-right: auto;
}
.pageTitle .searchIcon {
  color: #bfbfbf;
}

.addOrganization {
  margin: 12px 0;
}

.pageContent .addOrganizationBtn {
  background: #fff;
  color: #de7f39;
  border-color: #de7f39;
}
.healthOrganization {
  display: flex;
  padding: 30px;
  background: #fff;
}

.btnPanel {
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  width: 680px;
}

.btnPanel .navigationBtn {
  background: #fff;
  color: #de7f39;
  border-color: #de7f39;
}
.btnPanel .passwordBtn {
  width: 268px;
}
.btnPanel .restoreAccess {
  margin-left: auto;
  margin-right: 14px;
}

.tableWrapper :global(.ant-table-thead > tr > th),
.tableWrapper :global(.ant-table-tbody > tr > td),
.tableWrapper :global(.ant-table tfoot > tr > th),
.tableWrapper :global(.ant-table tfoot > tr > td) {
  padding: 16px 12px;
  height: 55px;
  color: #4f4f60;
}

.btnsColumn {
  width: 640px;
}
.tableButton {
  margin-right: 30px;
}
.tableButton:last-of-type {
  margin-right: 14px;
}
.pagination {
  display: flex;
  justify-content: flex-end;
  margin: 20px 0 60px;
}
.cellValue {
  word-break: break-word;
}
@media screen and (max-width: 812px) {
  .cellValue {
    word-break: inherit;
  }
}
