
.internalReportsForm {
  display: flex;
  flex-wrap: wrap;
}

.internalReportsHead {
  display: block;
  margin-bottom: 14px;
  font-weight: bold;
}

.required:after {
  content: ' *';
  color: red;
}

.internalReportsLabel {
  display: block;
  margin: 12px 0 16px 0;
  min-width: 120px;
}

.mr_20 {
  margin-right: 20px;
}

.pt_20 {
  padding-top: 20px;
}

.internalReportsForm .scheduledRidesReminderDay {
  width: 170px;
}

.internalReportsTitle {
  margin-top: 20px;
  display: block;
}

.internalReportsForm .customTimepicker {
  border-radius: 4px;
}

.internalReportsForm button {
  background: #fff !important;
  color: #de7f39 !important;
  border-color: #de7f39 !important;
  width: auto !important;
}

.internalReportsForm button:hover {
  background:  #de7f39 !important;
  color:  #fff !important;
  border-color: #de7f39 !important;
}

@media screen and (max-width: 975px) {
  .form {
    width: 100%;
  }
  .organizationLocation {
    width: 80%;
  }
}

@media screen and (max-width: 812px) {
  .organizationName {
    width: 100%;
  }
}
