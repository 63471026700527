.table :global(.ant-table-tbody > tr.ant-table-row:hover) .actions {
  opacity: 1;
  height: auto;
  height: 34px;
}

.table :global(.ant-table-tbody) .actionsColumn .defaultIcon.archiveIcon,
.table :global(.ant-table-tbody) .actionsColumn .actionsIcon.archiveIcon {
  display: flex;
}

.table :global(.ant-table-tbody > tr.ant-table-row:hover) .actionsColumn .defaultIcon,
.table :global(.ant-table-tbody > tr.ant-table-row:hover) .actionsColumn .actionsIcon {
  opacity: 1;
  height: auto;
}

.table :global(.ant-table-tbody > tr.ant-table-row:hover) .recurringInfo {
  opacity: 0;
  height: 0;
}

.actionsColumn {
  opacity: 1;
  transition: all 0.4s;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  background: #fff;
  border-radius: 17px;
}
.dflex
{
  display: flex;
  flex-direction: column;
}

.recurringInfo {
  display: flex;
  align-items: center;
  transition: all 0.4s;
  height: 34px;
  color: #4f4f60;
}

.actions,
.actionsColumn .defaultIcon,
.actionsColumn .actionsIcon {
  display: flex;
  align-items: center;
  opacity: 0;
  height: 0;
  transition: all 0.4s;
  width: 70px;  
  justify-content: center;
}
.twoActions {
  width: 70px;
}

.actionsColumn .defaultIcon,
.actionsColumn .actionsIcon {
  font-size: 22px;
}

.actionsColumn .defaultIcon {
  color: rgba(0, 0, 0, 0.25);
}
.ml5
{
  margin-left: 5px;
}
.icon_container {
  margin-left: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%; /* Adjust as needed */
  height: 100%; /* Adjust as needed */
}
.actionsColumn .actionsIcon {
  color: #de7f39;
}
.actionsColumn .defaultIcon:hover {
  cursor: not-allowed;
  opacity: 1;
}
.table :global(.ant-table-tbody > tr.ant-table-row:hover) .actionsColumn .actionsIcon:hover {
  opacity: 0.7;
  cursor: pointer;
}

.timezone {
  margin-left: 5px;
}

.draftIdCell,
.draftIdCellValue {
  max-width: 110px;
  width: 110px;
}

.riderCellValue,
.riderCell {
  max-width: 140px;
  width: 140px;
}

.vehicleCell,
.vehicleCellValue {
  max-width: 250px;
  width: 250px;
}

.pickUpDateCell,
.pickUpDateCellValue {
  max-width: 210px;
  width: 210px;
}

.dateCreatedCell {
  width: 110px;
  max-width: 110px;
}

.createdByCell,
.createdByCellValue {
  max-width: 110px;
  width: 110px;
}

.statusCell,
.statusCellValue {
  width: 120px;
  max-width: 120px;
}

.recurring {
  color: #4f4f60;
  cursor: pointer;
  font-weight: normal;
}
.recurringCellValue {
  max-width: 100px;
  width: 100px;
}
.cellValue {
  word-break: break-word;
}

.lastRide {
  color: #eb5757;
}

.actualWeight {
  color: #eb5757;
}
.bold {
  font-weight: 700;
}
