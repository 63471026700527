.setPasswordPage {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: center;
}

.inputControl {
  height: 64px;
  display: flex;
  flex-direction: column;
  line-height: 22px;
  font-size: 14px;
  font-weight: 400;
  color: #262626;
  margin: 30px 0;
}
.passwordInput {
  display: block;
  margin: 30px 0;
}
.resetForm {
  width: 370px;
  margin-bottom: 30px;
}
.resetForm .input {
  padding: 7px 11px;
  box-shadow: none;
  border: 1px solid #d9d9d9;
}
.btnsPanel {
  display: flex;
  justify-content: space-between;
}

.btnsPanel .navigationBtn {
  background: #fff;
  color: #de7f39;
  border-color: #de7f39;
}
.alignBtnsRight {
  justify-content: flex-end;
}
.button {
  width: 266px;
  height: 40px;
  margin: 30px auto 0 auto;
}

.alignedButton {
  width: auto;
  margin: 30px 0;
}

.setPasswordPage .btn {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  padding: 0 3px 0 0;
  border-color: #e0e0e0;
  margin-right: auto;
}
.required:after {
  content: ' *';
  color: red;
}
@media screen and (max-width: 812px) {
  .resetForm {
    width: 100%;
    padding: 0 10px;
  }
}
