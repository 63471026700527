.radioGroup:global(.ant-radio-group) {
  display: flex;
}
.radioButton :global(.ant-radio-input) {
  pointer-events: none;
}

.radioButton :global(.ant-radio-inner-checked) {
  border-color: #de7f39;
}

.radioButton :global(.ant-radio-inner::after) {
  background: #de7f39;
}

.radioButton :global(.ant-radio:hover .ant-radio-inner),
.radioButton :global(.ant-radio:focus .ant-radio-inner),
.radioButton :global(.ant-radio:active .ant-radio-inner) {
  border-color: #de7f39;
}
.radioButton:global(.ant-radio-wrapper:hover .ant-radio .ant-radio-inner:hover) {
  border-color: #de7f39 !important;

}
.radioButton :global(.ant-radio-input:focus + .ant-radio-inner) {
  border-color: #de7f39;
  box-shadow: 0 0 0 1px rgb(214 178 79 / 50%);
}
.radioButton :global(.ant-radio-checked .ant-radio-inner) {
  border-color: #de7f39;
  background-color: white;
}
.errorMessage {
  color: #f5222d;
  font-size: 12px;
  line-height: 22px;
}
.radioButton.error :global(.ant-radio-inner) {
  border-color: #f5222d;
}
