.tooltipOverlay :global(.ant-tooltip-inner) {
  border-radius: 8px;
}
.editTooltipOverlay:global(.ant-tooltip) {
  max-width: 410px;
}
.tooltipInfo {
  display: flex;
  justify-content: center;
  align-items: center;
}
