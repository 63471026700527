.riderInfo {
  width: 630px;
}

.checkboxLabel {
  margin-left: 8px;
}
.textCheckbox {
  margin-right: 24px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.callsCheckbox {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.caregiverCheckbox {
  display: inline-flex;
  align-items: center;
}
.numberField {
  display: block;
  width: 180px;
  margin-top: 17px;
}

.weight_height {
  display: flex;
  flex-wrap: wrap;
}

.ride_height {
  margin-left: 30px;
}
.birthDate {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  margin-bottom: 20px;
}

.patientUniqueId {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  margin-bottom: 20px;
}

.impairments {
  margin-bottom: 20px;
}
.impairmentHeader {
  display: block;
  margin-bottom: 14px;
}
.otherImpairment {
  display: block;
  margin: 10px 0 40px 0;
}
.checkboxesRow {
  display: flex;
  flex-wrap: wrap;
}

.checkboxContainer {
  margin-bottom: 16px;
  display: flex;
  width: 275px;
}
.checkbox {
  cursor: pointer;
  display: flex;
}

.checkboxLabel {
  margin-left: 8px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.personalInfo {
  height: 84px;
  width: 300px;
}

.measurement {
  display: flex;
  margin: 20px 0;
  width: 380px;
  justify-content: space-between;
}

.phoneInfo,
.communicationInfo,
.emailInfo,
.genderInfo,
.homeAddress {
  display: block;
  width: 300px;
  margin-bottom: 20px;
}

.homeAddress {
  width: 100%;
  height: auto;
}
.phoneInput {
  width: 300px;
}
.phoneCheckboxes {
  display: flex;
  flex-wrap: wrap;
}
.birthInfo {
  width: 300px;
  height: 84px;
  margin-right: 30px;
}

.patientUniqueIdLabel {
  height: 40px;
  width: 300px;
  margin-right: 30px;
}
.headTitle {
  margin: 0 0 50px 0;
  font-size: 24px;
}
.additionalContact {
  display: block;
  margin: 20px 0 20px 0;
}
.required:after {
  content: " *";
  color: red;
}

.errorMessage {
  color: #f5222d;
  font-size: 12px;
  line-height: 22px;
}

.btns .formControlBtn,
.confirmExactRiderButton .formControlBtn {
  background: #fff;
  color: #de7f39;
  border-color: #de7f39;
  width: auto;
}
.suggestions {
  margin: 20px 0;
}
.suggestionBtns {
  display: flex;
  max-width: 500px;
  justify-content: space-between;
  margin: 40px auto 0 auto;
}
.suggestionsList {
  padding: 0;
  margin: 0;
  list-style: none;
  max-height: 220px;
  overflow: scroll;
}
.confirmExactRiderButton {
  display: inline;
  margin-left: 10px;
}
.confirmExactRiderButton .formControlBtn {
  font-size: 13px;
  padding: 4px 8px;
  line-height: 20px;
}

.optionSuggestion {
  margin: 10px 0;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 4px;
}
.optionSuggestion:hover {
  background: #ec9d62;
  color: #fff;
}
.riderLabel .checkboxClassname {
  display: none;
}
.riderSelected {
  background: #de7f39;
  color: #fff;
}

.relationship {
  display: block;
  width: 400px;
}
.subTitle {
  line-height: 28px;
}

@media screen and (max-width: 975px) {
  .riderInfo {
    width: 100%;
  }
}
