.checkboxContainer {
  margin-bottom: 16px;
  display: flex;
  width: 275px;
}

.checkbox {
  cursor: pointer;
  display: flex;
}

.checkboxLabel {
  margin-left: 8px;
}
.needsEscort {
  display: block;
  margin: 40px 0px 40px;
}

.required:after {
  content: ' *';
  color: red;
}
.timeInput {
  display: block;
  width: 80px;
}
.timeControl {
  display: flex;
  align-items: flex-start;
  margin: 0 20px 10px 0;
}

.measurement {
  display: block;
  padding-top: 8px;
  margin-left: 10px;
}
