.pageContent {
  background: #fff;
  border-radius: 10px;
  padding: 0 37px 20px 37px;
}

.editRide {
  margin: 20px 0 0 23px;
}

.editForm {
  width: 630px;
  margin-top: 50px;
}

.editFormWithFlexibleHours {
  width: 830px;
}

.formBlock {
  margin-bottom: 90px;
}

.btns {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 20px 20px 20px 0;
  width: 500px;
}

.btnsWithFlexibleHours {
  width: auto;
}

.editForm .customSelect {
  margin-right: 20px;
  width: 450px;
}

.btns .navigationBtn {
  background: #fff;
  color: #de7f39;
  border-color: #de7f39;
}

.ml12 {
  margin-left: 12px;
}

.mt20 {
  margin-top: 20px;
}

.buttonControl {
  display: flex;
  align-items: center;
}

.sendDescription {
  margin: 60px 0 0;
  font-size: 14px;
  color: #828282;
}

.dateAndTime {
  display: flex;
  flex-wrap: wrap;
}

.dateAndTime .datepicker {
  margin: 0 30px 0 0;
  border-radius: 4px;
  max-width: 300px;
}

.range {
  display: flex;
  flex-wrap: wrap;
}

.dateAndTime .startDate {
  margin: 5px 20px 5px 0;
  max-width: 300px;
  height: 84px;
}

.container {
  display: flex;
  align-items: center;
}

.dropdown {
  display: block;
  width: 450px;
  height: 84px;
  margin: 5px 20px 5px 0;
}

.timePicker {
  width: 170px;
  margin-bottom: 20px;
}

.nestedDatepicker {
  margin-bottom: 0;
}

.timezone {
  display: block;
  width: 120px;
  margin: 5px 30px 5px 0;
}

.errorMessage {
  color: #f5222d;
  font-size: 12px;
  line-height: 22px;
}

.required:after {
  content: " *";
  color: red;
}

.alignSelf {
  align-self: center;
  margin: 20px 0;
}

.tooltipGap {
  margin-left: 15px;
}

.editForm .infoIcon {
  color: #fff;
  font-weight: bold;
}

.styledText {
  font-style: italic;
  font-weight: 500;
}

.timesContainer {
  display: grid;
  margin-top: 4px;
  margin-bottom: 10px;
}

.timesTitle {
  display: block;
}

.timesLabel {
  width: 380px;
  margin-bottom: 10px;
}
.informationMessage
{
  padding: 10px;
  background: #dd8036;
  border-radius: 10px;
  color: white;
  width: 450px;
  margin-bottom: 20px;
}
.timeInputFieldsContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.comment {
  display: block;
  max-width: 120px;
}

.deadhead {
  display: block;
  max-width: 120px;
}
.tooltipWrapper {
  align-self: center;
  margin: 20px;
}

.mileageWrapper {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}

.tooltipContent {
  display: block;
  padding: 4px;
}

.tooltipInfo {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #de7f39;
}

@media screen and (max-width: 1140px) {
  .editFormWithFlexibleHours {
    width: 100%;
  }

  .dateAndTime .startDate {
    width: 250px;
  }
}

@media screen and (max-width: 975px) {
  .editForm {
    width: 100%;
  }

  .dateAndTime .startDate {
    width: 215px;
  }

  @media screen and (max-width: 615px) {
    .dateAndTime .startDate {
      width: 250px;
    }
  }
}
