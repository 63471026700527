
.waitTimeContainer {
    margin-top: 20px;
    margin-bottom: 20px;
}
.waitTimeTitle {
    display: block;
    color: #ababab;
    margin: 0 0 6px 0;
}
.waitTimeLabel {
    width: 380px;
    margin-bottom: 10px;
}

.timeInputFieldsContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
}
.tooltipInfo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #de7f39;
  }
  
  .tooltipDisabled {
    background: #d9d9d9;
  }
  
 .infoIcon {
    color: #fff;
    font-weight: bold;
  }
  .tooltipContent {
    display: block;
    padding: 4px;
  }
