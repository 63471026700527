.loginWrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;
}

.pageHeader {
  margin: 80px 0 40px 0;
  line-height: 24px;
  font-size: 24px;
  color: #4f4f60;
}
.loginForm {
  width: 300px;
}
.loginInputControl {
  height: 64px;
  height: 84px;
  display: flex;
  flex-direction: column;
  line-height: 22px;
  font-size: 14px;
  font-weight: 400;
  color: #262626;
}
.passwordInput {
  margin: 18px 0 0px 0;
}

.forgotLink {
  display: inline-block;
  color: #de7f39;
  font-size: 14px;
  line-height: 22px;
  text-decoration: underline;
  margin: 0 0 38px 0;
}
.forgotLink:hover {
  opacity: 0.7;
  color: #de7f39;
}

.submitting {
  height: 40px;
  display: flex;
  margin-bottom: 20px;
  justify-content: flex-end;
  align-items: center;
}
.submit {
  width: 76px;
  height: 40px;
}

.rememberTitle {
  color: #333333;
  display: inline-block;
  margin-left: 8px;
  font-size: 14px;
  line-height: 24px;
  cursor: pointer;
}

.required:after {
  content: ' *';
  color: red;
}

@media screen and (max-width: 812px) {
  .pageHeader {
    margin-top: 49px;
  }
  .loginForm {
    width: 343px;
  }
}
