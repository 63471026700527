.pageContent {
  background: #fff;
  border-radius: 10px;
}
.pageTitle {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 12px 20px;
}
.title {
  font-size: 24px;
  line-height: 24px;
  margin: 0 40px 10px 0;
}
.inputField {
  width: 256px;
  margin: 0 auto 10px 0;
}
.pageTitle .searchIcon {
  color: #bfbfbf;
}
.add {
  margin-right: 15px;
}

.pageContent .addOrganizationBtn {
  background: #fff;
  color: #de7f39;
  border-color: #de7f39;
  margin-bottom: 10px;
}

.tableWrapper :global(.ant-table-thead > tr > th),
.tableWrapper :global(.ant-table-tbody > tr > td),
.tableWrapper :global(.ant-table tfoot > tr > th),
.tableWrapper :global(.ant-table tfoot > tr > td) {
  padding: 16px 12px;
  color: #4f4f60;
}
.ridersTable :global(.ant-table-tbody > tr.ant-table-row:hover > td) {
  background: #fff8f2;
  cursor: pointer;
}

.btnPanel {
  display: flex;
  justify-content: flex-end;
}

.btnPanel .navigationBtn {
  background: #fff;
  color: #de7f39;
  border-color: #de7f39;
}

.btnPanel .restoreAccess {
  margin-left: auto;
  margin-right: 14px;
}

.tableButton {
  margin-right: 30px;
}
.tableButton:last-of-type {
  margin-right: 14px;
}
.inputFieldWidth {
  width: 320px;
}
.pagination {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0 0 10px 0;
  min-height: 55px;
}
.gapForPagination {
  margin: auto 120px 10px 0;
}
.cellValue {
  word-break: break-word;
}

@media screen and (max-width: 1024px) {
  .pageTitle {
    justify-content: space-between;
  }
  
  .inputField {
    width: 220px;
    margin-right: 0;
  }
  .inputFieldWidth {
    width: 200;
  }

  .title {
    font-size: 18px;
    margin-right: 0;
  }

  .pageContent .addOrganizationBtn {
    font-size: 14px;
  }
}

@media screen and (max-width: 912px) {
  .inputField {
    width: 180px;
  }

  .inputFieldWidth {
    width: 160;
  }

  .pageContent .addOrganizationBtn {
    font-size: 13px;
  }
}

@media screen and (max-width: 812px) {
  .inputField {
    width: 220px;
    font-size: 13px;
  }

  .inputFieldWidth {
    width: 200;
  }
  
  .cellValue {
    word-break: inherit;
  }
}
