.headRow {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px;
  border-radius: 4px;
  background: rgb(168, 119, 119);
  color: rgb(79, 79, 96);
  cursor: pointer;
  font-weight: normal;
  word-break: break-word;
}
.headRowActive {
  background: #de7f39;
  color: #fff;
}

.headRowDisabled {
  background: #fff;
  color: #4f4f60;
}

.cellTitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.headRow .icon {
  color: #fff;
  transform: rotate(180deg);
  transition: all 0.3s;
}
.headRow .disabled {
  color: #de7f39;
  transform: rotate(0deg);
  transition: all 0.3s;
}
