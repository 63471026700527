.modalContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* width: 470px; */
  margin: 0 auto;
}
.modalDescription {
  text-align: center;
  margin: 0 auto 50px;
}
.form {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.cancelRideLabel {
  display: block;
  width: 320px;
  margin: 0 auto 16px auto;
}
.errorMessage {
  display: block;
  color: red;
  width: 325px;
  margin: 0 auto 16px auto;
}
.modalBtns {
  display: flex;
  justify-content: center;
  width: 290px;
  margin: 0 auto;
}
.modalBtns .navigationBtn {
  background: #fff;
  color: #de7f39;
  border-color: #de7f39;
}
.cancelBtns {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  width: 100%;
  /* width: 320px; */
}
.ml6 {
  margin-left: 6px;
}
.mr6 {
  margin-right: 6px;
}
.cancelRideBtn .customBtn {
  background: #fff;
  color: #de7f39;
  border-color: #de7f39;
}
.required:after {
  content: ' *';
  color: red;
}

@media screen and (max-width: 812px) {
  .modalContent {
    width: auto;
    padding: 0 20px;
  }
  .snackbarMsg {
    width: 100%;
  }
}
