.modalDescription {
  text-align: center;
  margin: 0 auto 40px;
}
.description {
  margin: 0 0 20px 0;
  text-align: center;
}
.modalBtns {
  display: flex;
  justify-content: space-between;
  width: 290px;
  margin: 24px auto 0 auto;
}
.modalBtns .navigationBtn {
  background: #fff;
  color: #de7f39;
  border-color: #de7f39;
}
.cancelBtns {
  width: 340px;
}

.datepicker {
  margin: 0 30px 20px 0;
  width: 280px;
}
.rideStatusForm {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 430px;
  margin: 0 auto;
  justify-content: center;
}
.rideStatusBtn {
  margin-top: 20px;
}
.setDateTime {
  margin: 300px auto 0 auto;
  height: 24px;
}

.setDateTime .dateTimeBtn {
  display: flex;
  align-items: center;
  height: 24px;
  padding: 1px 8px;
  line-height: 22px;
  font-size: 14px;
  font-weight: normal;
  border-radius: 4px;
}

.datepickerLabel {
  display: flex;
}
.dateHeader,
.timeHeader {
  text-align: center;
  font-weight: bold;
}
.dateHeader {
  width: 280px;
}
.timeHeader {
  width: 130px;
}

@media screen and (max-width: 812px) {
  .modalContent {
    width: auto;
    padding: 0 20px;
  }
}
