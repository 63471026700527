.notification {
  display: flex;
  align-items: center;
}

.message .notification .closeIcon {
  margin-left: 15px;
  color: #ababab;
  font-size: 14px;
}

.message .notification .closeIcon:hover {
  cursor: pointer;
}

.message :global(.ant-message-custom-content) {
  display: flex;
  align-items: center;
}
