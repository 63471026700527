.organizationName {
  display: block;
  width: 300px;
  margin-bottom: 40px;
}
.organizationLocation {
  display: block;
  margin: 40px 0 60px 0;
}

.name {
  display: flex;
  flex-wrap: wrap;
}
.nameLabel {
  display: block;
  width: 290px;
  margin-bottom: 20px;
  margin-right: 30px;
}

.emailContainer {
  display: flex;
  flex-wrap: wrap;
}

.changeAdminBtn {
  align-self: flex-start;
  margin-top: 24px;
}
.changeAdminBtn .formControlBtn,
.inviteBtn .formControlBtn {
  background: #fff;
  color: #de7f39;
  border-color: #de7f39;
}

.inviteBtn {
  align-self: flex-start;
  width: 290px;
  margin-top: 80px;
}
.email {
  display: block;
  width: 290px;
  margin-right: 30px;
}

.checkboxesColumn {
  display: flex;
  flex-direction: column;
  margin-top: 60px;
}
.checkbox {
  margin-bottom: 16px;
  display: flex;
  align-self: flex-start;
}
.wrappLabel {
  display: flex;
  align-self: flex-start;
}
.rideRequestFieldsHead {
  display: block;
  margin-bottom: 14px;
  font-weight: bold;
}
.checkboxLabel {
  margin-left: 8px;
  cursor: pointer;
}
.separator {
  display: flex;
  align-self: center;
  margin: 0 20px;
  width: 1px;
  height: 16px;
  background: #ababab;
}

.errorMessage {
  color: #f5222d;
  font-size: 12px;
  line-height: 22px;
}

.required:after {
  content: ' *';
  color: red;
}

@media screen and (max-width: 975px) {
  .form {
    width: 100%;
  }
}

@media screen and (max-width: 812px) {
  .modalContent {
    width: auto;
    padding: 0 20px;
  }

  .organizationName {
    width: 100%;
  }
}
