.checkbox > :global(.ant-checkbox > .ant-checkbox-inner) {
  border: 1px solid #d9d9d9;
  outline: none;
}
.checkbox :global(.ant-checkbox-input) {
  pointer-events: none;
}
.checkbox > :global(.ant-checkbox-checked > .ant-checkbox-inner) {
  background-color: #de7f39;
  border-color: #de7f39;
}

.checkbox :global(.ant-checkbox-inner::after) {
  left: 21%;
}

.checkbox > :global(.ant-checkbox-checked::after) {
  border: 1px solid #de7f39;
}

.checkbox :global(.ant-checkbox-input:focus + .ant-checkbox-inner),
.checkbox :global(.ant-checkbox:hover .ant-checkbox-inner) {
  border-color: #de7f39;
  box-shadow: 0 0 0 1px rgb(214 178 79 / 50%);
  outline: none;
}
.errorMessage {
  color: #f5222d;
  font-size: 12px;
  line-height: 22px;
}
.checkbox.error {
  border-color: #f5222d;
}
