.checkboxesColumn {
    display: flex;
    flex-direction: column;
    margin-top: 60px;
  }
  .checkbox {
    margin-bottom: 16px;
    display: flex;
    align-self: flex-start;
  }
  .wrappLabel {
    display: flex;
    align-self: flex-start;
  }
  .rideRequestFieldsHead {
    display: block;
    margin-bottom: 14px;
    font-weight: bold;
  }
  .checkboxLabel {
    margin-left: 8px;
    cursor: pointer;
  }
  .separator {
    display: flex;
    align-self: center;
    margin: 0 20px;
    width: 1px;
    height: 16px;
    background: #ababab;
  }