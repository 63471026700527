.datePicker {
  width: 100%;
  border-radius: 4px;
}
.datePicker:global(.ant-picker:hover),
.datePicker:global(.ant-picker-focused) {
  border-color: #de7f39;
  box-shadow: 0 0 0 1px rgb(214 178 79 / 50%);
}

.popUp
  :global(.ant-picker-cell-in-view.ant-picker-cell-selected
    .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start
    .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner),
.popUp :global(.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner),
.popUp :global(.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner),
.popUp :global(.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner) {
  color: #fff;
  background: #de7f39;
}

.popUp:global(.ant-picker-dropdown-placement-bottomLeft .ant-picker-range-arrow) {
  display: none;
}

.popUp
  :global(.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after),
.popUp
  :global(.ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after),
.popUp :global(.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after),
.popUp
  :global(.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after),
.popUp
  :global(.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after),
.popUp :global(.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after),
.popUp :global(.ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after) {
  border-color: #de7f39;
}

.popUp :global(.ant-picker-cell-in-view.ant-picker-cell-in-range::before),
.popUp :global(.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before),
.popUp
  :global(.ant-picker-date-panel
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start
    .ant-picker-cell-inner::after),
.popUp
  :global(.ant-picker-date-panel
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end
    .ant-picker-cell-inner::after) {
  background: #fff4ec;
  opacity: 0.7;
}

.popUp :global(.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before),
.popUp :global(.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before),
.popUp :global(.ant-picker-cell-range-hover-end) {
  background: #fff4ec;
}

.popUp :global(.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before),
.popUp :global(.ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before),
.popUp :global(.ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before),
.popUp
  :global(.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before),
.popUp
  :global(.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before),
.popUp
  :global(.ant-picker-panel
    > :not(.ant-picker-date-panel)
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before),
.popUp
  :global(.ant-picker-panel
    > :not(.ant-picker-date-panel)
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before) {
  background: #fff8f2;
}

.datePicker.error {
  border-color: red;
}

.popUp :global(.ant-picker-panel:last-child) {
  /* display: none; */
}

.popUp :global(.ant-picker-header .ant-picker-header-next-btn),
.popUp :global(.ant-picker-header .ant-picker-header-super-next-btn) {
  visibility: visible !important;
}

.popUp :global(.ant-picker-panels .ant-picker-panel:first-child .ant-picker-header .ant-picker-header-next-btn),
.popUp :global(.ant-picker-panels .ant-picker-panel:first-child .ant-picker-header .ant-picker-header-super-next-btn) {
  visibility: hidden !important;
}

.errorMessage {
  color: #f5222d;
  font-size: 12px;
  line-height: 22px;
}

.popUp :global(.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before) {
  border: 1px solid #de7f39;
}
.popUp :global(.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner) {
  background: #de7f39;
}
.popUp :global(.ant-picker-today-btn) {
  color: #de7f39;
}
.popUp :global(.ant-picker-today-btn):hover {
  color: #de7f39;
  opacity: 0.6;
}

.popUp :global(.ant-picker-time-panel) {
  width: 170px;
}

.popUp :global(.ant-picker-header-view) {
  margin: 5px;
  line-height: 1.58;
  color: #de7f39;
  border-radius: 4px;
}

.popUp :global(.ant-picker-header button) {
  line-height: 1.58;
}

.popUp :global(.ant-picker-month-btn),
.popUp :global(.ant-picker-year-btn) {
  background: #fff0e5;
  margin: 4px;
  padding: 0 12px;
  border-radius: 4px;
}
.popUp :global(.ant-picker-header button):hover {
  color: #de7f39;
  opacity: 0.6;
}

.popUp :global(.ant-picker:hover),
.popUp :global(.ant-picker-focused) {
  border-color: #de7f39;
  box-shadow: 0 0 0 1px rgb(214 178 79 / 50%);
}
.popUp :global(.ant-picker-time-panel-column) {
  width: 50px;
}

.popUp :global(.ant-picker-now-btn) {
  color: #de7f39;
}
.popUp
  :global(.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner) {
  background: #de7f39;
}
.popUp :global(.ant-picker-ok button) {
  background: #de7f39;
  border-color: #de7f39;
}
.popUp :global(.ant-picker-ok button:disabled) {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  pointer-events: none;
}
.popUp :global(.ant-picker-ok button:hover),
.popUp :global(.ant-picker-ok button:focus),
.popUp :global(.ant-picker-ok button:active) {
  border: 1px solid #de7f39;
}
.datePicker:global(.ant-picker-range .ant-picker-active-bar) {
  background: #de7f39;
  bottom: -2px;
  height: 3px;
}
.popUp.hideFooter :global(.ant-picker-ranges) {
  display: none;
}
@media (max-width: 560px) {
  .popUp :global(.ant-picker-panel-container) {
    display: flex;
    flex-direction: column;
  }
  .popUp :global(.ant-picker-panels) {
    display: flex;
    flex-direction: column;
  }
}
