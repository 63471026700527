.input {
  display: block;
  padding: 7px 11px;
  outline: none;
  border-color: #de7f39;
  box-shadow: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  color: #333;
  line-height: 24px;
}
.input::placeholder {
  color: #bfbfbf;
}
.input:active,
.input:focus,
.input:hover,
.input::selection {
  outline: none;
  border-color: #de7f39;
  box-shadow: 0 0 0 1px rgb(214 178 79 / 50%);
}

.input:disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}

.input:disabled:hover {
  outline: none;
  border-color: #d9d9d9;
  box-shadow: 0 0 0 1px rgb(214 178 79 / 50%);
}
.errorMessage {
  color: #f5222d;
  font-size: 12px;
  line-height: 22px;
}

.input.error {
  border-color: #f5222d;
}
