.modal {
  width: 670px;
  border-radius: 10px;
  overflow: hidden;
}
.modal {
  animation-duration: 0s !important;
}
.modal :global(.ant-modal-content) {
  border-radius: 10px;
  padding: 60px 0;
}
.modal :global(.ant-modal-header) {
  border-bottom: none;
  text-align: center;
}

.modal :global(.ant-modal-title) {
  font-size: 24px;
  line-height: 24px;
  color: #4f4f60;
}

.modal :global(.ant-modal-body) {
  padding: 0;
}
