.setPasswordPage {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: center;
  padding: 0 20px;
}
.head {
  width: 370px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 120px;
}
.pageHeader {
  font-size: 24px;
  line-height: 24px;
  color: #4f4f60;
  margin: 0;
}

.pageDescription {
  margin: 20px 0 40px 0;
  width: 368px;
  font-size: 14px;
  line-height: 24px;
  font-weight: normal;
  text-align: center;
  color: #4f4f60;
}

.resetForm {
  width: 370px;
  margin-bottom: 30px;
}
.resetForm .input {
  padding: 7px 11px;
  box-shadow: none;
  border: 1px solid #d9d9d9;
}

@media screen and (max-width: 812px) {
  .head {
    margin-top: 49px;
    width: 325px;
  }
  .pageDescription {
    width: 300px;
  }
  .pageHeader {
    margin-left: 60px;
  }
  .resetForm {
    width: 100%;
    padding: 0 10px;
  }
}
