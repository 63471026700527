.nameRow {
  display: flex;
}

.name {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.nameLabel {
  display: block;
  width: 290px;
  margin-bottom: 20px;
}
.emailContainer {
  display: flex;
  flex-wrap: wrap;
}

.email {
  display: block;
  width: 290px;
  margin-right: 30px;
}
.personalInfoLabel {
  display: block;
  width: 290px;
  margin-bottom: 10px;
}
.personalInfo {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.required:after {
  content: ' *';
  color: red;
}
