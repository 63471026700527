.confirmPopUp:global(.ant-notification-hook-holder),
.confirmPopUp:global(.ant-notification-notice) {
  width: 240px;
  background: #4f4f60;
  color: #fff;
  border-radius: 4px;
  padding: 12px 0;
  font-size: 14px;
}

.confirmPopUp :global(.ant-notification-notice-message) {
  display: none;
}

.confirmPopUp :global(.ant-notification-notice-message) {
  color: #fff;
}
.descriptionBody {
  display: flex;
  flex-direction: column;
}
.message {
  padding: 0 12px;
  color: #fff;
}
.dismissBtn {
  align-self: flex-end;
  color: #333333;
  margin: 13px 16px 0 0;
}
