
.modalContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    width: 750px;
  }
  .modalDescription {
    text-align: center;
    margin: 0 auto 50px;
  }
.routes {
    display: flex;
    margin: 26px 0 30px 0;
  }
  
  .destionationRouteInfo {
    margin-left: 20px;
    display: flex;
    align-self: flex-start;
  }
  
  .separator {
    display: flex;
    align-self: center;
    margin: 0 20px;
    width: 1px;
    height: 16px;
    background: #ababab;
  }
  
  .modalBtns .navigationBtn {
    background: #fff;
    color: #de7f39;
    border-color: #de7f39;
  }

  .cancelBtns {
  display: flex;
  gap: 10px;
    bottom: 20px;
    left: 0;
    right: 0;
    width: 100%;
  }
  .ml6 {
    margin-left: 6px;
  }
  .mr6 {
    margin-right: 6px;
  }

  .roundTripHeading {
    text-align: center;
    font-size: 14px;
  }

  
  @media screen and (max-width: 575px) {
    .arrows {
      width: 35px;
    }
  
    .latestSnackbar {
      width: auto;
    }
  }
  @media screen and (max-width: 812px) {
    .modalContent {
      width: auto;
      padding: 0 20px;
    }}