.pageContent {
  background: #fefdfc;
  border-radius: 10px;
  padding: 40px 16px 0 16px;
  width: 100%;
  max-width: 812px;
  margin: 0 auto;
  min-height: 100vh;
}
.header {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}
.backBtn {
  cursor: pointer;
}
.pageTitle {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 12px 30px 12px 20px;
}
.form {
  width: 300px;
  margin: 0 36px;
}
.cancelBtns {
  max-width: 812px;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-between;
  box-shadow: 0px -3px 25px rgb(217 172 139 / 12%);
  background: #fff;
  padding: 22px 10px;
}
.cancelBtns .navigationBtn {
  background: #fff;
  color: #de7f39;
  border-color: #de7f39;
  margin-right: 30px;
}

.required:after {
  content: ' *';
  color: red;
}
.commentLabel {
  display: block;
  margin-top: 32px;
}

.roundTripHeading {
  text-align: center;
  font-size: 14px;
  padding-bottom: 10px;
}

.roundTripHeadingText {
  text-align: center;
  font-size: 14px;
}


.routes {
  display: block; 
  margin-left: 36px;
  margin-right: 36px;
  margin-bottom: 30px;
  margin-top: 30px;
}

.destionationRouteInfo {
  margin-top: 15px;
  align-self: flex-start;
  flex-direction: row;
}
.align_center
{
  text-align: center
}

.arrows {
  width: 97px;
  align-self: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.separator {
  display: flex;
  align-self: center;
  margin: 0 20px;
  width: 1px;
  height: 16px;
  background: #ababab;
}

.arrowDown {
  transform: rotate(90deg);
}

.arrowUp {
  transform: rotate(270deg);
}

@media screen and (max-width: 400px) {
  .pageContent {
    width: 350px;
  }
}
