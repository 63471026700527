.pageContent {
  background: #fff;
  border-radius: 10px;
  padding: 40px 37px;
}

.pageTitle {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 12px 30px 12px 20px;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
}
.backBtn {
  cursor: pointer;
}
.rideDetails {
  margin-left: 23px;
}

.bold {
  font-weight: 700;
}
