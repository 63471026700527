.weekRangePicker {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 20px;
}

.day {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid grey;
  margin-right: 20px;
  cursor: pointer;
  border-color: #d9d9d9;
}
.weekRangePicker .checkboxClassname {
  display: none;
}

.weekTitle {
  color: #bfbfbf;
}

.dayLabel {
  width: 40px;
  margin: 0 10px;
  user-select: none;
}
.disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}

.selected {
  background: #de7f39;
  color: #fff;
}

.disabled.selected {
  background: #de7f39a8;
}
.required:after {
  content: ' *';
  color: red;
}
