.wrapper {
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  position: fixed;
  z-index: 10001;
  background: #ffffff69;
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.progress {
  width: 150px;
  height: 150px;
  margin-top: 75px;
}
.logo {
  position: static;
  opacity: 1;
  top: 30%;
  overflow: hidden;
}

.logo:before {
  content: '';
  position: absolute;
  top: 0;
  left: -100px;
  width: 70px;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
  transform: skewX(-30deg);
  animation-name: slide;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.13) 0%,
    rgba(255, 255, 255, 0.13) 77%,
    rgba(255, 255, 255, 0.5) 92%,
    rgba(255, 255, 255, 0) 100%
  );
}
@keyframes slide {
  0% {
    left: 0%;
    top: 0;
  }
  50% {
    left: 50%;
    top: 0px;
  }
  100% {
    left: 100%;
    top: 0;
  }
}

.loading span {
  display: inline-block;
  vertical-align: middle;
  width: 15px;
  height: 15px;
  margin: 50px 10px 0 10px;
  background: #de7f39;
  border-radius: 0.6em;
  animation: loading 1s infinite alternate;
}

.loading span:nth-of-type(2) {
  background: #e08c51;
  animation-delay: 0.2s;
}
.loading span:nth-of-type(3) {
  background: #dd935e;
  animation-delay: 0.4s;
}

@keyframes loading {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
