.pageContent {
  background: #fff;
  border-radius: 10px;
  padding: 40px 20px 40px 20px;
}
.header {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}
.backBtn {
  cursor: pointer;
}
.pageTitle {
  margin: 0 0 0 30px;
  color: #333;
  font-size: 24px;
  line-height: 24px;
}
.form {
  width: 610px;
  margin-top: 20px;
}
.btnPanel {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 60px 0 20px 0;
}

.btnControl {
  margin-bottom: 20px;
}

.btnPanel .navigationBtn {
  background: #fff;
  color: #de7f39;
  border-color: #de7f39;
}

@media screen and (max-width: 975px) {
  .form {
    width: 100%;
  }
}
