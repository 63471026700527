
.userPreferencesContainer {
  display: block;
  width: 500px;
  margin-bottom: 16px;
}

.userPreferencesHead {
  display: block;
  margin-top: 28px;
  margin-bottom: 16px;
  font-weight: bold;
}

.userPreferencesLabel {
  margin-top: 20px;
  /* margin-bottom: 2px; */
  display: block;
}

.userPreferencesInput {
  display: flex;
  width: 80px;
  margin-bottom: 8px;
}

.userPreferencesInput2 {
  width: 136px;
}

.userPreferencesInput .measurement {
  display: block;
  padding-top: 8px;
  margin-left: 10px;
}

.required:after {
  content: ' *';
  color: red;
} 

.userPreferencesBtnContainer button {
  background: #fff !important;
  color: #de7f39 !important;
  border-color: #de7f39 !important;
  width: auto !important;
  margin-top: 12px !important;
  margin-bottom: 4px !important
}

.userPreferencesBtnContainer button:hover {
  background:  #de7f39 !important;
  color:  #fff !important;
  border-color: #de7f39 !important;
}

@media screen and (max-width: 975px) {
  .form {
    width: 100%;
  }
  .organizationLocation {
    width: 80%;
  }
}

@media screen and (max-width: 812px) {
  .organizationName {
    width: 100%;
  }
}
