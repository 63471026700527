.tableButton {
  margin-right: 30px;
}
.tableButton:last-of-type {
  margin-right: 14px;
}
.passwordBtn.navigationBtn {
  width: 268px;
  background: #fff;
  color: #de7f39;
  border-color: #de7f39;
}
