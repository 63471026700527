.pageContent {
    background: #fff;
    border-radius: 10px;
    padding: 0px 37px 40px 60px;
  }
  
  .cancelRideBtn {
    width: auto;
    max-width: 250px;
    display: flex;
    gap: 10px;
  }
  
  .cancelRideBtn .customBtn {
    background: #fff;
    color: #de7f39;
    border-color: #de7f39;
  }
  .bold {
    font-weight: 700;
  }
  