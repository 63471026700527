.organizationName {
  display: block;
  width: 300px;
  margin-bottom: 20px;
}

.organizationLocation {
  display: block;
  margin-bottom: 30px;
}

.name {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.nameLabel {
  display: block;
  width: 300px;
  margin-bottom: 20px;
}

.timezone {
  display: block;
  width: 300px;
  margin-top: 20px;
}

.email {
  display: block;
  width: 300px;
  margin: 10px 0 20px 0;
}

.personalInfoLabel {
  display: block;
  width: 300px;
  margin-bottom: 10px;
}

.personalInfo {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.credentials {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.changeEmail {
  margin: 0;
}

.minimumTimeToSubmit {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 16px;
}

.minimumTimeToSubmitTitle {
  margin-bottom: 12px;
  margin-right: 28px;
}

.rideExpiration {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.mt28 {
  margin-top: 28px;
}

.inputControl {
  display: flex;
  flex-direction: column;
  line-height: 22px;
  font-size: 14px;
  font-weight: 400;
  color: #262626;
}

.passwordInput {
  display: block;
  margin: 10px 0 20px 0;
  width: 300px;
}

.credentials .input {
  padding: 7px 11px;
  box-shadow: none;
  border: 1px solid #d9d9d9;
}

.forgotLink {
  display: inline-block;
  color: #de7f39;
  font-size: 14px;
  line-height: 22px;
  text-decoration: underline;
}

.forgotLink:hover {
  opacity: 0.7;
  color: #de7f39;
}

.credentialsPasswordAndLink {
  display: none;
}

.passwordWithLink {
  width: 300px;
}

.passwordWithHint {
  width: 300px;
}

.passwordHint {
  display: block;
  width: 300px;
  color: #828282;
  font-size: 12px;
  line-height: 18px;
  margin-top: 22px;
}

.changePasswordBtn {
  width: 160px;
  margin: 0 0 20px 0;
}

.required:after {
  content: ' *';
  color: red;
}

.errorMessage {
  color: #f5222d;
  font-size: 12px;
  line-height: 22px;
}

@media screen and (max-width: 893px) {
  .changePasswordAndLink {
    display: none;
  }

  .credentialsPasswordAndLink {
    display: block;
  }
}

@media screen and (max-width: 812px) {
  .changePasswordAndLink {
    display: block;
  }

  .credentialsPasswordAndLink {
    display: none;
  }

  .nameLabel,
  .email,
  .passwordInput,
  .passwordWithLink,
  .passwordHint,
  .organizationName,
  .timezone,
  .passwordWithHint,
  .personalInfoLabel {
    width: 100%;
  }
}

@media screen and (max-width: 812px) {
  .changePasswordAndLink {
    display: none;
  }

  .credentialsPasswordAndLink {
    display: block;
  }
}