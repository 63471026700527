.errorBoundary {
  background: #fff;
  border-radius: 10px;
  padding: 27px 37px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.reload {
  display: inline-block;
  margin-left: 8px;
  cursor: pointer;
  color: #de7f39;
}
.head {
  background: #ffd6b9;
  padding: 10px;
  border-radius: 4px;
}
.error {
  margin: 0;
}
