.checkboxLabel {
  margin-left: 8px;
}
.headTitle {
  margin: 0 0 50px 0;
  font-size: 24px;
}

.form {
  width: 630px;
}

.additionalOptionsHead {
  display: block;
  margin-bottom: 14px;
  font-weight: bold;
}

.checkboxesColumn {
  display: inline-flex;
  flex-direction: column;
}
.checkbox {
  margin-bottom: 16px;
  display: flex;
}
.costCenter,
.additionalSource {
  display: block;
  width: 100%;
  margin-bottom: 40px;
}
.required:after {
  content: ' *';
  color: red;
}

.otherFieldsContainer {
  display: flex;
  flex-direction: row;
}

.otherFieldColumn1 {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.otherFieldColumn2 {
  display: flex;
  flex-direction: row;
  min-width: 12px;
  justify-content: flex-end;
}

.otherFieldColumn2>.requiredContainer {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}
@media screen and (max-width: 975px) {
  .form {
    width: 100%;
  }
}
