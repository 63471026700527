.datePicker:global(.ant-picker) {
  width: 100%;
  height: 40px;
  border-radius: 4px;
}
.datePicker {
  animation-duration: 0s !important;
}

.datePicker:global(.ant-picker:hover),
.datePicker:global(.ant-picker-focused) {
  border-color: #de7f39;
  box-shadow: 0 0 0 1px rgb(214 178 79 / 50%);
}
.datePicker.hideInputPanel {
  overflow: hidden;
  height: 0;
  padding: 0;
  margin: 0;
  border: none;
}

.datePicker.error {
  border-color: red;
}

.errorMessage {
  color: #f5222d;
  font-size: 12px;
  line-height: 22px;
}
.popUp {
  animation-duration: 0s !important;
}

.popUp.hideInputPanel :global(.ant-picker-panel-container) {
  box-shadow: none;
  border: 1px solid #d9d9d9;
}

.popUp.hideInputPanel :global(.ant-picker-footer) {
  display: none;
}

.popUp :global(.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before) {
  border: 1px solid #de7f39;
}
.popUp :global(.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner) {
  background: #de7f39;
}
.popUp :global(.ant-picker-today-btn) {
  color: #de7f39;
}
.popUp :global(.ant-picker-today-btn):hover {
  color: #de7f39;
  opacity: 0.6;
}

.popUp :global(.ant-picker-time-panel) {
  width: 170px;
}

.popUp :global(.ant-picker-header-view) {
  margin: 5px;
  line-height: 1.58;
  color: #de7f39;
  border-radius: 4px;
}

.popUp :global(.ant-picker-header button) {
  line-height: 1.58;
}

.popUp :global(.ant-picker-month-btn),
.popUp :global(.ant-picker-year-btn) {
  background: #fff0e5;
  margin: 4px;
  padding: 0 12px;
  border-radius: 4px;
}
.popUp :global(.ant-picker-header button):hover {
  color: #de7f39;
  opacity: 0.6;
}

.popUp :global(.ant-picker:hover),
.popUp :global(.ant-picker-focused) {
  border-color: #de7f39;
  box-shadow: 0 0 0 1px rgb(214 178 79 / 50%);
}
.popUp :global(.ant-picker-time-panel-column) {
  width: 50px;
}

.popUp :global(.ant-picker-now-btn) {
  color: #de7f39;
}
.popUp
  :global(.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner) {
  background: #de7f39;
}
.popUp :global(.ant-picker-ok button) {
  background: #de7f39;
  border-color: #de7f39;
}
.popUp :global(.ant-picker-ok button:disabled) {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  pointer-events: none;
}
.popUp :global(.ant-picker-ok button:hover),
.popUp :global(.ant-picker-ok button:focus),
.popUp :global(.ant-picker-ok button:active) {
  border: 1px solid #de7f39;
}
