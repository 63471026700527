.loginWrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;
}
.email {
  margin: 0 10px;
}

.pageDescription {
  margin: 100px 0 40px 0;
  font-size: 18px;
  line-height: 24px;
  font-weight: normal;
  text-align: start;
  color: #232327;
}

@media screen and (max-width: 812px) {
  .pageHeader {
    margin-top: 49px;
  }
  .loginForm {
    width: 343px;
  }
}
