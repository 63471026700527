.notificationSection {
  border: 1px solid #de7f39;
  border-radius: 4px;
  padding: 16px;
  margin-bottom: 20px;
}
.notification {
  display: flex;
  width: 580px;
}
.notificationMessage {
  margin-left: 17px;
}

.notificationTitle {
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  color: #262626;
}
.notificationContent {
  margin: 0;
  font-size: 14px;
  line-height: 22px;
  color: #595959;
}
.notification .closeIcon {
  color: #de7f39;
  fill: #de7f39;
  font-size: 21px;
}
.actionBtn {
  width: 165px;
  position: relative;
  left: 70%;
}

.actionBtn .customBtn {
  background: #fff;
  color: #de7f39;
  border-color: #de7f39;
}
