.modalForm {
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto 0 auto;
}

.btnPanel {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 60px 0 20px 0;
}

.btnControl {
  margin-bottom: 20px;
}

.btnPanel .navigationBtn {
  background: #fff;
  color: #de7f39;
  border-color: #de7f39;
}

.modalContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 500px;
  margin: 0 auto;
}
.modalDescription {
  text-align: center;
  margin: 0 auto 40px;
}
.modalBtns {
  width: 100%;
  display: flex;
  justify-content: space-between;
  width: 300px;
}
.modalBtns .navigationBtn {
  background: #fff;
  color: #de7f39;
  border-color: #de7f39;
}
.selectUser {
  margin: 0 0 40px 0;
  width: 400px;
}
.errorMessage {
  color: #f5222d;
  font-size: 12px;
  line-height: 22px;
}

@media screen and (max-width: 812px) {
  .modalContent {
    width: auto;
    padding: 0 20px;
  }
  .modalForm {
    width: auto;
  }
  .selectUser {
    width: 100%;
  }
}
