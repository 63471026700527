.pageContent {
  background: #fff;
  border-radius: 10px;
  padding: 40px 20px 0 20px;
}
.header {
  display: flex;
  align-items: center;
  margin-bottom: 72px;
}

.pageSection:after {
  content: '';
  display: block;
  height: 1px;
  background: #f2f2f2;
  margin: 40px 0;
}
.pageTitle {
  margin: 0;
  color: #333;
  font-size: 24px;
  line-height: 24px;
}
.form {
  width: 630px;
}

.btnPanel {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 20px 0 20px 0;
}

.btnPanel .navigationBtn {
  background: #fff;
  color: #de7f39;
  border-color: #de7f39;
}

@media screen and (max-width: 975px) {
  .form {
    width: 100%;
  }
  .btns .formButton {
    padding: 6px 10px;
    font-size: 14px;
  }
}
