.form {
  width: 880px;
  margin-top: 20px;
}

.personalInfoForm {
  width: 630px;
  margin-top: 20px;
}

.btnPanel {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 60px 0 20px 0;
}

.btnPanel .navigationBtn {
  background: #fff;
  color: #de7f39;
  border-color: #de7f39;
}

.modalContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 460px;
  margin: 0 auto;
}
.modalDescription {
  text-align: center;
  margin: 0 auto 50px;
}
.modalBtns {
  display: flex;
  justify-content: space-around;
}
.modalBtns .navigationBtn {
  background: #fff;
  color: #de7f39;
  border-color: #de7f39;
}

.optionName {
  font-weight: bold;
}

.archiveModalBtn {
  margin: 0 40px;
}

.archiveModalBtns {
  width: 200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
}

.rideExpiration {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
.expirationTitle {
  font-weight: 700;
  font-size: 14px;
  display: block;
  margin-bottom: 14px;
}
.controlTitle {
  display: block;
}
.titleGap {
  margin-top: 26px;
}
.timeControl {
  display: flex;
  align-items: flex-start;
  margin-right: 20px;
  margin-bottom: 16px;
}
.reminder {
  margin-bottom: 16px;
}

.measurement {
  display: block;
  padding-top: 8px;
  margin-left: 10px;
}
.minimumTimeToRequest {
  font-weight: 700;
  font-size: 14px;
  display: block;
  margin: 60px 0 20px;
}

.minimumTimeToSubmit {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 16px;
}
.minimumTimeToSubmitTitle {
  width: 380px;
  margin-bottom: 10px;
}

.addtionalRequestInput {
  width: 300px;
  margin: 20px 30px 0 0;
}

.addtionalRequestOption {
  margin-right: 10px;
}

.addtionalOption {
  margin: 0 40px 0 0;
}

.addRideOptionField {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  align-items: center;
}

.archivedField {
  display: inline-flex;
  align-items: flex-start;
  flex-wrap: wrap;
  align-items: center;
}

.additionalRequestOptions .formControlBtn {
  background: #fff;
  color: #de7f39;
  border-color: #de7f39;
  width: auto;
}

.rideExpiration .formControlBtn,
.supportField .formControlBtn,
.switcherField .formControlBtn {
  background: #fff;
  color: #de7f39;
  border-color: #de7f39;
  width: auto;
}

.addtionalRequestOption .formControlBtn {
  padding: 5px 10px;
}

.archivedSubTitle {
  display: block;
  font-weight: bold;
}

.saveBtn {
  margin-right: 30px;
  margin-top: 20px;
}
.additionalRequestOptions .addNewOptionBtn {
  width: 300px;
  margin-top: 20px;
}
.personalInfo {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.optionsRow {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.optionsRow::after {
  content: '';
  display: block;
  width: 250px;
}

.optionsSection {
  margin-left: 20px;
}
.rideOption {
  margin: 20px 40px 20px 0;
  width: 200px;
}
.optionDisabled {
  color: #767676;
}

.archivedOptions {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.archivedOption {
  margin-bottom: 16px;
  width: 275px;
  cursor: pointer;
}

.archivedTitle {
  color: #767676;
  margin: 0;
  font-style: italic;
  padding: 5px 10px;
}

.activeTitle {
  display: block;
  font-weight: bold;
  margin: 30px 0 0px 0;
}
.supportContactBlock {
  margin-bottom: 20px;
}
.supportContact {
  display: block;
  width: 350px;
  margin: 0 20px 24px 0;
  height: 40px;
}
.switcherContact {
  display: block;
  width: 320px;
}
.switcherField {
  display: flex;
  align-items: center;
  height: 40px;
  margin-bottom: 10px;
}
.supportContact .supportEmail {
  width: 100%;
}
.supportField {
  display: flex;
  align-items: flex-end;
  margin-bottom: 24px;
}

.switcher {
  display: inline-block;
  width: 220px;
}
.required:after {
  content: ' *';
  color: red;
}

.errorMessage {
  color: #f5222d;
  font-size: 12px;
  line-height: 22px;
}

.showOptions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: normal;
  margin-left: 20px;
  padding: 5px;
  background: #fafafa;
  cursor: pointer;
  border-radius: 4px;
}

.groupTitle .showOptionsArrow {
  transition: all 0.3s;
  margin-left: 10px;
  font-size: 14px;
  transform: rotate(180deg);
}

.hideOptions .showOptionsArrow {
  transform: rotate(0);
}
.groupTitle {
  display: flex;
  align-items: center;
  margin: 50px 0 20px 0;
}
@media screen and (max-width: 1170px) {
  .form,
  .personalInfoForm {
    width: 100%;
  }
}

@media screen and (max-width: 812px) {
  .modalContent {
    width: auto;
    padding: 0 20px;
  }
}
