.createRide {
  background: #fff;
  border-radius: 10px;
  padding: 40px 37px;
}
.header {
  display: flex;
  align-items: center;
  margin-bottom: 72px;
}
.backBtn {
  cursor: pointer;
}
.form {
  width: 630px;
}

.pageTitle {
  margin: 0 0 0 30px;
  color: #333;
  font-size: 24px;
  line-height: 24px;
}
.steps {
  margin-bottom: 72px;
}

.modalContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 500px;
  margin: 0 auto;
}
.modalDescription {
  text-align: center;
  margin: 0 auto 50px;
}
.modalBtns {
  display: flex;
  justify-content: space-around;
}
.modalBtns .navigationBtn {
  background: #fff;
  color: #de7f39;
  border-color: #de7f39;
}

@media screen and (max-width: 1024px) {
  .createRide {
    padding-bottom: 70px;
  }
}

@media screen and (max-width: 975px) {
  .form {
    width: 100%;
  }
}

@media screen and (max-width: 812px) {
  .createRide {
    padding: 10px 10px 80px 10px;
  }
  .modalContent {
    width: auto;
    padding: 0 20px;
  }
}
