.leg {
  border-radius: 20px;
  background: #fff4ec;
  color: #de7f39;
  text-align: center;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  font-weight: 500;
  width: 80px;
}
.firstLeg {
  width: 260px;
}
.secondLeg {
  width: 285px;
}
.overlay :global(.ant-popover-inner) {
  background: #de7f39;
  padding: 5px 8px;
  color: #fff;
  border-radius: 4px;
}

.overlay :global(.ant-popover-inner .ant-popover-inner-content) {
  color: inherit;
  padding: 0;
  font-size: 14px;
}
.overlay:global(.ant-popover-placement-topLeft > .ant-popover-content > .ant-popover-arrow) {
  border-right-color: #de7f39;
  border-bottom-color: #de7f39;
}
.overlay:global(.ant-popover-placement-topLeft
    > .ant-popover-content
    > .ant-popover-arrow
    > .ant-popover-arrow-content) {
  top: -1px;
}
.overlay :global(.ant-popover-arrow-content::before) {
  background: #de7f39;
}
.leg .icon {
  color: #de7f39;
  margin-right: 4px;
}
