.pageContent {
  background: #fff;
  border-radius: 10px;
  padding: 40px 37px;
}
.editRide {
  margin: 50px 0 0 23px;
}

.editForm {
  width: 630px;
  margin-top: 60px;
}
.formBlock {
  margin-bottom: 90px;
}
.pageTitle {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 12px 30px 12px 20px;
}
.title {
  font-size: 24px;
  line-height: 24px;
  margin: 12px 40px 12px 0;
}
.otherLeg {
  margin-bottom: 50px;
}
.link {
  color: #de7f39;
}
.link:hover {
  opacity: 0.7;
  color: #de7f39;
}

.header {
  display: flex;
  align-items: center;
}

.driverEmail {
  color: #de7f39;
  margin-right: 5px;
}
.driverEmail:hover {
  opacity: 0.7;
  color: #de7f39;
}
.createdAt {
  margin: 20px 0 15px 0;
}
.sendDescription {
  margin: 60px 0 0;
  font-size: 14px;
  color: #828282;
}

.btns {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 16px;
}

.btns .navigationBtn {
  background: #fff;
  color: #de7f39;
  border-color: #de7f39;
}

.buttonControl {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.toolTip {
  margin-left: 20px;
}
.tooltipInfo {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #de7f39;
}
.tooltipInfo .infoIcon {
  color: #fff;
  font-weight: bold;
}

.tooltipContent {
  padding: 4px;
}

@media screen and (max-width: 975px) {
  .editForm {
    width: 100%;
  }
}
