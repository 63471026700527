.rideDetails {
  background: #fff;
  border-radius: 10px;
  padding: 40px 37px 40px 60px;
  align-items: center;
}
.form {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.formRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 480px;
  align-self: flex-end;
}
.rescheduleContainer
{
  display: flex;
  justify-content: space-between;
   
   width: 60%;
  padding: 40px 37px 40px 60px;
  align-items: center;
}
.fitContent
{
  width: fit-content !important;
}
.driverLabel {
  display: block;
  width: 300px;
  margin-right: 20px;
  margin-bottom: 30px;
}
.driverLabelText {
  margin-right: 20px;
}
.driverLabelContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-bottom: 10px;
}
.formRow .styledBtn {
  margin-right: 20px;
}
.btnWithTooltip {
  display: flex;
  align-items: center;
  margin-right: 60px;
  margin-bottom: 30px;
}

.rideDetails .navigationBtn {
  width: 120px;
  background: #fff;
  color: #de7f39;
  border-color: #de7f39;
  margin-top: 60px;
}
 
/* .tooltipInfo {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #de7f39;
} */
/* .tooltipContent {
  padding: 4px;
} */
.tooltipDisabled {
  background: #d9d9d9;
}

.form .infoIcon {
  color: #fff;
  font-weight: bold;
}
.manageDriversLink {
  display: inline-block;
  color: #de7f39;
  font-size: 14px;
  line-height: 22px;
  text-decoration: underline;
  margin-bottom: 30px;
}
.manageDriversLink:hover {
  opacity: 0.7;
  color: #de7f39;
}
.modalContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  width: auto;
  
  align-items: baseline;
  max-height: 720px;
  padding: 10px 30px 0 !important;

  p {
    margin: 0;
  }
}
.rideStatusModal :global(.ant-modal-content) {
 
  overflow-x: hidden;
  overflow-y: auto;
  
  min-height: 520px;
  max-height: 720px;
  padding: 10px 30px 0 !important;
 
}
.rideStatusModal :global(.ant-modal-header) {
  padding: 0 0 5px !important;
  border-bottom: none;
  text-align: center;
}

.rideStatusModal :global(.ant-alert-with-description) {
  padding-block: 10px;
  border-radius: 5px;
}

.datePickerWrapper {
  position: relative; 
  height: 335px;
}

.rideStatusEndedModal :global(.ant-modal-content) {
  height: 730px;
}

.rideStatusForm :global(.ant-picker-dropdown) {
  margin-top: -20px;
  position: sticky;
}

.cancelRideLabel {
  display: block;
  width: 320px;
  margin-bottom: 20px;
}

.modalDescription {
  text-align: center;
  margin: 0 auto 40px;
}
.modalBtns {
  display: flex;
  justify-content: space-between;
  width: 290px;
  margin: 4px auto;
}

.ml6 {
  margin-left: 6px;
}
.mr6 {
  margin-right: 6px;
}

.modalBtns .navigationBtn {
  background: #fff;
  color: #de7f39;
  border-color: #de7f39;
}
.cancelBtns {
  position: absolute;
  bottom: 10px; 
  left: 0;
  right: 0;
}
.roundTripHeading {
  text-align: left;
  font-size: 14px;
  padding-bottom: 10px;
}

.roundTripHeadingText {
  text-align: center;
  font-size: 14px;
}


.boldWeight {
  font-weight: bold;
}
.checkboxesColumn {
  display: inline-flex;
  flex-direction: column;
  width: 370px;
  margin: 0 auto;
}
.checkbox {
  margin-bottom: 16px;
}
.checkboxLabel {
  margin-left: 8px;
}
.declineForm {
  display: flex;
}

.datepicker {
  margin: 0 30px 20px 0;
  width: 280px;
}
.rideStatusForm {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 430px;
  margin: 0 auto;
  justify-content: center;
}
.rideStatusBtn {
  margin-top: 25px;
  width: 180px;
}
.setDateTime {
  margin: 15px auto 0 auto;
  height: 24px;
}

.setDateTime .dateTimeBtn {
  display: flex;
  align-items: center;
  height: 24px;
  padding: 1px 8px;
  line-height: 22px;
  font-size: 14px;
  font-weight: normal;
  border-radius: 4px;
}
.comment {
  display: block;
  width: auto;
  margin: 20px 20px 30px;
}

.snackbarMsg {
  width: 620px;
}

.datepickerLabel {
  display: flex;
}
.dateHeader,
.timeHeader {
  text-align: center;
  font-weight: bold;
}
.dateHeader {
  width: 280px;
}
.timeHeader {
  width: 130px;
}

.required:after {
  content: ' *';
  color: red;
}

.deadheadWrapper
{
  display: flex; 
 flex-wrap: wrap;  
 margin: 20px 20px 30px;
}
.deadhead {
  display: block;
  width: 90%;
 
}
.tooltipWrapper
{
  align-self: center;
    margin-top: 20px;
    margin-left: 5px;
    color: white;
}
.routes {
  display: block; 
  margin-left: 36px;
  margin-right: 36px;
  margin-bottom: 15px;
}

.destionationRouteInfo {
  margin-top: 20px;
  align-self: flex-start;
  flex-direction: row;
}
.align_center
{
  text-align: center
}

.arrows {
  width: 97px;
  align-self: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.separator {
  display: flex;
  align-self: center;
  margin: 0 20px;
  width: 1px;
  height: 16px;
  background: #ababab;
}

.arrowDown {
  transform: rotate(90deg);
}

.arrowUp {
  transform: rotate(270deg);
}

/* .deadheadWrapper
{
  display: flex; 
 flex-wrap: wrap;  
 margin: 20px 20px 30px;
} */

.tooltipContent {
  display: block;
  padding: 4px;
  
}

.tooltipInfo {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #de7f39;
}

 
 

@media screen and (max-width: 812px) {
  .modalContent {
    width: auto;
    padding: 0 20px;
    align-items: center;
  }
  .snackbarMsg {
    width: 100%;
  }
}
