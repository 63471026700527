.customPicker {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}
.units {
  align-self: flex-start;
  display: block;
  width: 150px;
}
.customRepeats {
  margin-right: 30px;
  display: block;
  width: 120px;
}
.required:after {
  content: ' *';
  color: red;
}
