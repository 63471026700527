.billSegmentHeadings {
  font-weight: 700;
  font-size: 14px;
  display: block;
  margin: 60px 0 20px;
}
.Switcher {
  flex: 0;
  margin-left: 30px;
}
.renderField {
  display: flex;
  margin-bottom: 20px;
  align-items: center;
}
.btnPanel {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 10px 0 20px 0;
}
.labelBilling{
  margin-right: 10px;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 40px;
  margin-top: 20px;
}
.labelSubcsription{
  flex: 0;
  margin-right: 10px;
  min-width: 11%;
  line-height: 40px;
}
.inputfield{
  width: 90px;
  min-width: 35%;
  display: flex;
}
.colHeader{
  font-weight: 500;
}
.inputPlaceHolder
{ 
  min-width: 35%;
  display: flex;
  align-items: center;
}
.fieldData{
  margin-bottom: 40px;
}
.renderFieldView{
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.labeldata{
width: 90px; 
margin-right: 30px ;
}
.renderTimeinput{
  margin: 0 20px;
}
.labelRidecost{
  display: flex;
  flex: 0 1;
  flex-direction: column;
  justify-content: center;
  min-width: 20%;
}
.mb20 {
  margin-bottom: 20px;
}

@media (max-width: 968px) {
  .labelSubcsription {
    min-width: 15%; 
  }
 
}
