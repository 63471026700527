.autocomplete {
  border-radius: 4px;
}

.autocomplete .item {
  border-radius: 4px;
  padding: 5px;
  background-color: #fff;
  color: #000;
  cursor: pointer;
}

.autocomplete .active {
  background-color: #de7f39;
  color: #fff;
}
.errorMessage {
  color: #f5222d;
  font-size: 12px;
  line-height: 22px;
}
.input.customInput.error {
  border-color: #f5222d;
}
.d_block
{
  display: block;
}

.addressTitle
{
  display: block;
  text-align: justify;
}
.popover {
  width: 500px;
  max-width: 500px;
}

.container {
  /* display: flex; */
  position: relative;
}

.favAddressWrapper {
  display: flex;
}
.addressBookButtons
{
  display: flex;
      justify-content: flex-end;
      width: 83px;
      margin-left: -76px;
      margin-top: -5px;
      z-index: 1;
}

.favButton
{
  position: relative;
  right: 5px;
  top: 5px;
}



.popOver:global(.ant-popover-open) .backIcon {
  transform: rotate(450deg);
  transition: all 0.3s;
}

.backIcon {
  transform: rotate(269deg);
  margin-left: 10px;
  margin-bottom: 3px;
  width: 9px;
  height: 13px;
  transition: all 0.3s;
}

.addressBookButton {
  padding: 0px;
  right: 15px;
  top: 10px;
  border: 0px;
  width: 20px;
 
    background: transparent;
}

.popOver {
  padding: 0;
}

.popOver :global(.ant-popover-inner-content) {
  padding: 9px 40px 9px 12px;
}
.popOverList {
  padding: 0;
  margin: 0;
  list-style: none;
}

.popOverListItem {
  font-size: 14px;
  line-height: 22px;
  padding-bottom: 10px;
  color: #4f4f4f;
}

.popOverListItem:last-of-type {
  padding-bottom: 0;
}

.popOverListItem > a,
.popOverListItem > .popOverOption {
  color: #4f4f4f;
}
.popOverListItem:hover > a,
.popOverListItem:hover > .popOverOption {
  color: #e67726;
  cursor: pointer;
  opacity: 0.7;
}

.closeBtn:hover {
  color: #e67726;
  cursor: pointer;
  opacity: 0.7;
}

.btn {
  margin-left: auto;
}

.disabled {
  cursor: not-allowed;
  opacity: 0.4;
}


.overlay :global(.ant-popover-content > .ant-popover-arrow) {
  right: 4px !important;
}

.popOver .styledBtn {
  display: flex;
  align-items: flex-end;
  background: transparent;
  color: black;
  border: navajowhite;
  box-shadow: none;
  transition: none;
  padding: 1px 7px 1px 10px;
  color: #4f4f4f;
  font-size: 16px;
  line-height: 24px;
}

.popOver .styledBtn:hover,
.popOver .styledBtn:active,
.popOver .styledBtn:focus {
  background: transparent;
  color: black;
  border: navajowhite;
  box-shadow: none;
  transition: none;
  opacity: 0.7;
}
.popOver .styledBtn:hover > span,
.popOver .styledBtn:active > span,
.popOver .styledBtn:focus > span,
.popOver .styledBtn:hover > svg,
.popOver .styledBtn:focus > svg {
  opacity: 0.7;
}

.userName {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

 


