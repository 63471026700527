.spinnerWrapper {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  position: fixed;
  z-index: 10001;
  background: #ffffff69;
}

.spinnerWrapper :global(.ant-spin-dot-item) {
  background: #de7f39;
  opacity: 1;
}
