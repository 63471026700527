.pageContent {
  background: #fff;
  border-radius: 10px;
  padding: 0px 37px 40px 60px;
}

.cancelRideBtn {
  width: 145px;
}

.cancelRideBtn .customBtn {
  background: #fff;
  color: #de7f39;
  border-color: #de7f39;
}

.driversBtns .arrivalBtn {
  background: #1cb200 !important;
  border-color: #1cb200 !important;
}

.driversBtns .arrivalBtn:hover,
.driversBtns .arrivalBtn:active,
.driversBtns .arrivalBtn:focus {
  background: #1cb200 !important;
  border-color: #1cb200 !important;
}

.bold {
  font-weight: 700;
}
