.modalContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 330px;
  margin: 0 auto;
}
.modalDescription {
  text-align: center;
  margin: 0 auto 20px;
}
.modalBtns {
  display: flex;
  justify-content: space-between;
  margin-top: 43px;
}

.modalBtns .navigationBtn {
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
}

.modalBtns .cancelBtn {
  background: #fff;
  color: #de7f39;
  border-color: #de7f39;
}
.form {
  width: 140px;
  margin: 0 auto;
}

.checkboxRow {
  display: flex;
  justify-content: space-between;
}
.checkbox {
  margin-bottom: 16px;
}
.checkboxLabel {
  margin-left: 8px;
}
