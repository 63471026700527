.modalContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  width: auto;
  align-items: baseline;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 720px;
  padding: 10px 30px 0 !important;

  p {
    margin: 0;
  }
}

.modalDescription {
  text-align: center;
  margin: 0 auto 50px;
}
.form {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.cancelRideLabel {
  display: block;
  width: 320px;
  margin: 0 auto 16px auto;
}
.modalBtns {
  display: flex;
  justify-content: space-between;
  column-gap: 20px;
  width: 290px;
  margin: 0 auto;
}
.modalBtns .navigationBtn {
  background: #fff;
  color: #de7f39;
  border-color: #de7f39;
}
.cancelRideBtn .customBtn {
  background: #fff;
  color: #de7f39;
  border-color: #de7f39;
}
.cancelRideLabel {
  display: block;
  width: 300px;
  margin: 0 auto 16px auto;
}
.cancelBtns {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
}
.ml6 {
  margin-left: 6px;
}
.mr6 {
  margin-right: 6px;
}
.required:after {
  content: " *";
  color: red;
}

.routes {
  display: block;
  margin-left: 36px;
  margin-right: 36px;
  margin-bottom: 15px;
}

.destionationRouteInfo {
  margin-top: 20px;
  align-self: flex-start;
  flex-direction: row;
}
.align_center {
  text-align: center;
}

.roundTripHeading {
  text-align: left;
  font-size: 14px;
  padding-bottom: 10px;
}

.roundTripHeadingText {
  text-align: center;
  font-size: 14px;
}

.separator {
  display: flex;
  align-self: center;
  margin: 0 20px;
  width: 1px;
  height: 16px;
  background: #ababab;
}

.arrowDown {
  transform: rotate(90deg);
}

.arrowUp {
  transform: rotate(270deg);
}

:global(.ant-modal-title) {
  font-size: 24px;
  line-height: 24px;
  color: #4f4f60;
  padding-left: 40px;
  padding-right: 40px;
}

@media screen and (max-width: 812px) {
  .modalContent {
    width: auto;
    padding: 0 20px;
  }
  .snackbarMsg {
    width: 100%;
  }
}
