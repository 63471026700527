.pageTitle {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0;
    padding: 12px 20px;
  }
  
  .tableFooter {
    display: flex;
    flex-wrap: wrap;
    justify-content: end;
  }
  
  .title {
    font-size: 18px;
    margin-right: 0;
  }

  .pageHeader {
    font-size: 24px;
    line-height: 24px;
    color: #4f4f60;
    margin: 0;
  }
  
  .pageContent :global(.ant-table-tbody > tr.ant-table-row:hover) .actionsColumn {
    opacity: 1;
  }
  
  .actionIcon
  {
    margin-right: 5px;
    vertical-align: 0px;
  }
  
  .pageSection:after {
    content: '';
    display: block;
    height: 1px;
    background: #f2f2f2;
    margin: 80px 0;
  }
   
 
  .btnPanel {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 15px 0 20px 0;
  }
  
  .btnPanel .navigationBtn {
    background: #fff;
    color: #de7f39;
    border-color: #de7f39;
    width: 175px;
  }

  
.tableWrapper :global(.ant-table-thead > tr > th),
.tableWrapper :global(.ant-table-tbody > tr > td),
.tableWrapper :global(.ant-table tfoot > tr > th),
.tableWrapper :global(.ant-table tfoot > tr > td) {
  padding: 5px 4px;
  height: 25px;
  color: #4f4f60;
}

.btnsColumn {
  width: 640px;
}
.tableButton {
  margin-right: 30px;
}
.tableButton:last-of-type {
  margin-right: 14px;
}
.pagination {
  display: flex;
  justify-content: flex-end;
  margin: 20px 0 60px;
}
.cellValue {
  word-break: break-word;
}
.pageContent :global(.ant-table-expanded-row-fixed) {
    padding: 0 !important;
    padding: 16px 8px !important;
    margin: 0 !important;
    width: auto !important;
  }
  
  @media screen and (max-width: 812px) {
    .pagination {
      margin-top: 30px;
    }}