.sidebar :global(.ant-layout-sider-children) {
  background-color: #de7f39;
  border-radius: 10px;
  min-height: 100%;
  height: auto;
}

.sidebar :global(.ant-menu-inline-collapsed) {
  width: 100%;
}

.sidebar:global(.ant-layout-sider.ant-layout-sider-dark) {
  padding: 0;
  border-radius: 10px;
  margin-left: 110px;
  background: transparent;
  margin-top: 90px;
  height: calc(100vh - 90px);
  position: fixed;
  z-index: 3;
  left: 0;
  overflow-y: auto;
}
.sidebar :global(.ant-menu.ant-menu-dark) {
  background-color: #de7f39 !important;
  padding-top: 115px;
  border-radius: 10px;
  height: 100%;
  width: 100%;
}
 
.sidebar :global(.ant-menu-item-icon),
.sidebar :global(.ant-menu-title-content),
.sidebar :global(.ant-menu-title-content a),
.sidebar :global(.ant-menu-item-icon svg) {
  display: block;
  height: 38px;
  line-height: 38px;
}
.sidebar :global(.ant-menu-dark .ant-menu-submenu-open) {
  color: inherit;
}

.sidebar :global(.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected) {
  background: transparent;
}

.sidebar :global(.ant-menu-dark.ant-menu-inline .ant-menu-item::after) {
  border-right: 12px solid white;
  border-radius: 10px;
  right: -6px;
}

.sidebar :global(.ant-menu-inline-collapsed.ant-menu-vertical .ant-menu-item-selected::after) {
  border-right: 12px solid white;
  border-radius: 10px;
  right: -6px;
  opacity: 1;
  transform: scaleY(1);
}

.sidebar :global(.ant-menu-item svg),
.sidebar :global(.ant-menu-submenu svg) {
  fill: #ffc499;
}

.sidebar :global(.ant-menu) > .customMenuItem {
  margin: 20px 0;
  height: 38px;
   
  line-height: 38px;
  display: flex;
  align-items: center;
}

.sidebar :global(.ant-menu) > .customMenuItem.subOptionMenu {
  display: block;
}

.sidebar :global(.ant-menu-dark .ant-menu-submenu > .ant-menu-submenu-title > .ant-menu-submenu-arrow) {
  opacity: 0.45;
}

.sidebar :global(.ant-menu-dark .ant-menu-inline.ant-menu-sub) {
  background: transparent;
}

.sidebar :global(.ant-menu-item .ant-menu-item-icon + span),
.sidebar :global(.ant-menu-submenu-title .ant-menu-item-icon + span),
.sidebar :global(.ant-menu-item .anticon + span),
.sidebar :global(.ant-menu-submenu-title .anticon + span),
.sidebar :global(.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow),
.sidebar :global(.ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow) {
  transition: all 0.3s, color 0s;
 
}

.customMenuItem:global(.ant-menu-submenu-active .ant-menu-item-icon svg),
.customMenuItem:global(.ant-menu-submenu-selected .ant-menu-item-icon svg),
.customMenuItem:global(.ant-menu-item-active .ant-menu-item-icon svg),
.customMenuItem:global(.ant-menu-item-selected .ant-menu-item-icon svg),
.sidebar :global(.ant-menu-item-active.ant-menu-item-only-child svg),
.sidebar :global(.ant-menu-item-selected.ant-menu-item-only-child svg) {
  fill: #fff;
}
.sidebar :global(.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow),
.sidebar :global(.ant-menu-dark .ant-menu-item-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow) {
  opacity: 1;
}
.sidebar :global(.ant-menu .ant-menu-submenu .ant-menu-submenu-title) {
  max-width: 175px;
  overflow: hidden;
  display: flex;
}

.sidebar :global(.ant-menu .ant-menu-submenu .ant-menu-submenu-title .ant-menu-submenu-arrow) {
  margin-top: 3.7px;
}

.sidebar :global(.ant-menu .ant-menu-submenu.ant-menu-submenu-open .ant-menu-submenu-title .ant-menu-submenu-arrow) {
  margin-top: 0px;
}
.sidebar :global(.ant-menu-sub.ant-menu-inline > .ant-menu-item, ),
.sidebar :global(.ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title) {
  height: 38px;
}
.submenuTitle {
  margin-left: 10px;
  color: inherit;
  font-style: inherit;
}

.subMenuContent {
  display: flex;
  align-items: center;
}

.sidebar :global(.ant-menu-submenu-arrow::before),
.sidebar :global(.ant-menu-submenu-arrow::after) {
  width: 9px;
}

.sidebar :global(.ant-menu-submenu-inline .ant-menu-submenu-arrow::before) {
  transform: rotate(-45deg) translateX(4px);
}
.sidebar :global(.ant-menu-submenu-inline .ant-menu-submenu-arrow::after) {
  transform: rotate(45deg) translateX(-4px);
}

.sidebar
  :global(.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before) {
  transform: rotate(45deg) translateX(4px);
}

.sidebar :global(.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title),
.sidebar :global(.ant-menu-vertical > .ant-menu-item),
.sidebar :global(.ant-menu-vertical-left > .ant-menu-item),
.sidebar :global(.ant-menu-vertical-right > .ant-menu-item),
.sidebar :global(.ant-menu-inline > .ant-menu-item),
.sidebar :global(.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title),
.sidebar :global(.ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title),
.sidebar :global(.ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title),
.sidebar :global(.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title) {
  height: 38px;
  line-height: 38px;
}

.sidebar :global(.ant-menu-vertical > .ant-menu-item, .ant-menu-vertical-left > .ant-menu-item),
.sidebar :global(.ant-menu-vertical-right > .ant-menu-item),
.sidebar :global(.ant-menu-inline > .ant-menu-item),
.sidebar :global(.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title),
.sidebar :global(.ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title),
.sidebar :global(.ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title),
.sidebar :global(.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title) {
  height: 38px;
  line-height: 38px;
}

.sidebar :global(.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon),
.sidebar
  :global(.ant-menu.ant-menu-inline-collapsed
    > .ant-menu-item-group
    > .ant-menu-item-group-list
    > .ant-menu-item
    .ant-menu-item-icon),
.sidebar
  :global(.ant-menu.ant-menu-inline-collapsed
    > .ant-menu-item-group
    > .ant-menu-item-group-list
    > .ant-menu-submenu
    > .ant-menu-submenu-title
    .ant-menu-item-icon),
.sidebar :global(.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon),
.sidebar :global(.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .anticon),
.sidebar:global(.ant-menu.ant-menu-inline-collapsed
    > .ant-menu-item-group
    > .ant-menu-item-group-list
    > .ant-menu-item
    .anticon),
.sidebar
  :global(.ant-menu.ant-menu-inline-collapsed
    > .ant-menu-item-group
    > .ant-menu-item-group-list
    > .ant-menu-submenu
    > .ant-menu-submenu-title
    .anticon),
.sidebar :global(.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon) {
  line-height: 38px;
  font-size: 14px;
  min-width: 38px !important;
}

:global(.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon + span),
:global(.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu .ant-menu-item-icon + span) {
  flex: auto;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
:global(.ant-menu-submenu-popup > .ant-menu) {
  background-color: #de7f39 !important;
}
:global(.ant-menu-sub.ant-menu-vertical .ant-menu-item-selected) {
  background-color: #a15723e6 !important;
  opacity: 0.7;
}
.sidebar
  :global(.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after) {
  transform: rotate(-45deg) translateX(-4px);
}

.sidebar:after {
  display: flex;
  flex-grow: 1;
  display: none;
  background: red;
  opacity: 0.8;
  content: '';
  position: absolute;
  left: 200px;
  width: calc(100vw - 200px);
  height: calc(100vh - 90px);
  top: 0;
}

@media screen and (max-width: 812px) {
  .sidebar:global(.ant-layout-sider.ant-layout-sider-dark) {
    margin: 90px 0 0 0;
    overflow-y: auto;
  }
}
