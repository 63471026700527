.pageContent {
  background: #fff;
  border-radius: 10px;
  padding: 40px 20px 0 20px;
  color: #333333;
}
.header {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}
.pageTitle {
  margin: 0;
  color: #333;
  font-size: 24px;
  line-height: 24px;
}

.pageSection:after {
  content: '';
  display: block;
  height: 1px;
  background: #f2f2f2;
  margin: 80px 0;
}
