.pageContent {
  background: #fff;
  border-radius: 10px;
}
.pageTitle {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 12px 30px 12px 20px;
}
.title {
  font-size: 24px;
  line-height: 24px;
  margin: 12px 40px 12px 0;
}
.pagination {
  display: flex;
  justify-content: flex-end;
  margin: 20px 0 50px;
}

.draftPage {
  display: flex;
  flex-direction: column;
}

.draftPage :global(.ant-table-tbody > tr.ant-table-row:hover) .actionsColumn {
  opacity: 1;
}

.actionsColumn {
  opacity: 0;
  transition: all 0.4s;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.actionsColumn .actionsIcon {
  color: #de7f39;
  font-size: 22px;
  display: inline-block;
}
.actionsColumn .actionsIcon:first-child {
  margin-right: 15px;
}
.actionsColumn .actionsIcon:hover {
  opacity: 0.7;
}
.modalContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 280px;
  margin: 0 auto;
}
.modalDescription {
  text-align: center;
  margin: 0 auto 50px;
}
.modalBtns {
  width: 280px;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
}
.modalBtns .navigationBtn {
  background: #fff;
  color: #de7f39;
  border-color: #de7f39;
}

.timezone {
  margin-left: 5px;
}

.draftIdCell,
.draftIdCellValue {
  max-width: 90px;
  width: 90px;
}
.riderCell,
.riderCellValue {
  max-width: 190px;
  width: 190px;
}

.vehicleCell,
.vehicleCellValue {
  max-width: 270px;
  width: 270px;
}

.pickUpDateCell,
.pickUpDateCellValue {
  max-width: 210px;
  width: 210px;
}
.createdAtCell,
.createdAtValue {
  width: 210px;
  max-width: 210px;
}

.createdByCell,
.createdByCellValue {
  max-width: 150px;
  width: 150px;
}

.cellValue {
  word-break: break-word;
}
.bold {
  font-weight: 700;
}
