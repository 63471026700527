.forgotPage {
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;
}
.head {
  width: 495px;
  display: flex;
  align-items: center;
  margin-top: 120px;
}
.pageHeader {
  margin: 0 auto;
  font-size: 24px;
  line-height: 24px;
  color: #4f4f60;
}
.pageDescription {
  margin: 20px 0 40px 0;
  width: 368px;
  font-size: 14px;
  line-height: 24px;
  font-weight: normal;
  text-align: center;
  color: #4f4f60;
}

.inputControl {
  height: 64px;
  display: flex;
  flex-direction: column;
  line-height: 22px;
  font-size: 14px;
  font-weight: 400;
  color: #262626;
}
.resetForm {
  width: 300px;
}
.resetForm .input {
  padding: 7px 11px;
  box-shadow: none;
  border: 1px solid #d9d9d9;
}
.submit {
  width: 266px;
  height: 40px;
  margin: 30px auto 20px auto;
}
.forgotPage .btn {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  padding: 0 3px 0 0;
  border-color: #e0e0e0;
}

.required:after {
  content: ' *';
  color: red;
}
@media screen and (max-width: 812px) {
  .head {
    margin-top: 49px;
    width: 325px;
  }
  .pageDescription {
    width: 300px;
  }
  .pageHeader {
    margin: 0 auto;
  }
}
