.organizationName {
  display: block;
  width: 300px;
  margin-bottom: 40px;
}

.name {
  display: flex;
  flex-wrap: wrap;
  margin: 60px 0 0 0;
}

.organizationName {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 20px 0;
}

.organizationLocation {
  display: block;
  margin: 40px 0 60px 0;
}

.nameLabel {
  display: block;
  width: 290px;
  margin-bottom: 20px;
  margin-right: 30px;
}

.address {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.errorMessage {
  color: #f5222d;
  font-size: 12px;
  line-height: 22px;
}

.addCounties {
  height: 40px;
  margin: 14px 0 20px 0;
  align-self: flex-end;
}

.states {
  font-weight: 700;
}

.statesSelect {
  width: 150px;
  margin: 14px 0 20px 0;
}

.countiesSelect {
  width: 290px;
  margin: 14px 0 20px 0;
}

.regions {
  display: block;
  margin-top: 60px;
}

.email {
  display: block;
  width: 290px;
  margin-right: 30px;
}

.checkboxesColumn {
  display: inline-flex;
  flex-direction: column;
  margin-top: 60px;
}

.carSeatColumn {
  display: inline-flex;
  flex-direction: column;
  margin-top: 30px;
}


.checkbox {
  margin-bottom: 16px;
  display: flex;
  cursor: pointer;
}

.additionalOptions .checkbox {
  width: 275px;
}

.rideRequestFieldsHead {
  display: block;
  margin-bottom: 14px;
  font-weight: bold;
}

.checkboxLabel {
  margin-top: 4px;
  margin-left: 8px;
}

.emailContainer {
  display: flex;
  flex-wrap: wrap;
}

.changeAdminBtn {
  align-self: flex-start;
  margin-top: 24px;
}

.changeAdminBtn .formControlBtn,
.inviteBtn .formControlBtn {
  background: #fff;
  color: #de7f39;
  border-color: #de7f39;
}

.inviteBtn {
  align-self: flex-start;
  width: 290px;
  margin-top: 80px;
}

.additionalOptions {
  display: block;
  margin-top: 40px;
}

.additionalOptionsHead {
  display: block;
  margin-bottom: 14px;
  font-weight: bold;
}

.checkboxesRow {
  display: flex;
  flex-wrap: wrap;
}

.checkboxesList {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.countiesLabels {
  display: flex;
  flex-wrap: wrap;
  height: auto;
  overflow: visible;
  transition: all 0.3s;
}

.hideTagsList {
  height: 0;
  overflow: hidden;
}

.visibleTagsList {
  height: auto;
  overflow: visible;
}

.stateLabel {
  display: flex;
  align-items: center;
}

.showCountiesButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: normal;
  margin-left: 20px;
  padding: 5px;
  background: #fafafa;
  cursor: pointer;
  border-radius: 4px;
}

.stateLabel .showCountiesArrow {
  transition: all 0.3s;
  margin-left: 10px;
  font-size: 14px;
  transform: rotate(180deg);
}

.hideCountiesButton .showCountiesArrow {
  transform: rotate(0);
}

.tagsList {
  display: flex;
  flex-direction: column;
  padding: 10px 0;
}

.tagsList:not(:last-child)::after {
  content: '';
  display: block;
  height: 1px;
  background: #f2f2f2;
  margin: 10px 0;
}

.tag {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  padding: 1px 8px;
  border-radius: 4px;
  font-size: 12px;
  line-height: 20px;
  margin: 0 14px 14px 0;
  cursor: pointer;
}

.tag:hover {
  background: #de7f39;
  color: #fff;
}

.tag:hover .closeIcon {
  color: #fff;
}

.tagsList .clearAllCounties {
  margin-left: 5px;
  font-size: 12px;
}

.stateTitle {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
}

.countiesLabels .closeIcon {
  color: rgba(0, 0, 0, 0.45);
  font-size: 10px;
  margin-left: 5px;
}

.required:after {
  content: ' *';
  color: red;
}

.rideExpiration {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.minimumTimeToSubmitTitle {
  display: block;
  margin: 10px 0 16px 0;
  min-width: 120px;
}

.dailyReminder .scheduledRidesReminderDay {
  width: 170px;
}

.dailyReminderLabel {
  margin-top: 20px;
  display: block;
}

.dailyReminder {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 500px;
}

.dailyReminder .customTimepicker {
  border-radius: 4px;
}

.panelRender {
  padding-bottom: 3px;
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  justify-content: space-around;
}

.vehicles {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.numberField {
  display: block;
  width: 90px !important;
  margin-right: 30px;
  margin-bottom: 30px;
}

.fontWeight_500 {

  font-weight: 500;
  white-space: nowrap;

}
.fleetTitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.lineHeight_60 {

  line-height: 60px;

}
.resetMarginTop {

  margin-top: 0;

}

@media screen and (max-width: 975px) {
  .form {
    width: 100%;
  }

  .organizationLocation {
    width: 80%;
  }
}

@media screen and (max-width: 812px) {
  .organizationName {
    width: 100%;
  }
}