.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 40px 11px 110px;
  background: #fff;
  position: fixed;
  z-index: 3;
  width: 100vw;
}

.driverHeader {
  width: 100vw;
  max-width: 812px;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  padding: 20px 5px;
}

.userRole {
  margin: 0;
  font-size: 14px;
  line-height: 24px;
  color: #e67726;
  margin-left: 30px;
  font-weight: 700;
}

.requestRide {
  margin-left: auto;
  margin-right: 60px;
}

.requestRide .active {
  background: #f5f5f5;
  border: 1px solid #d9d9d9;
  color: #bfbfbf;
}

.requestRide .active:hover,
.requestRide .active:active,
.requestRide .active:focus {
  background: #f5f5f5;
  border: 1px solid #d9d9d9;
  color: #bfbfbf;
}
.viewDashboardTitle {
  margin: 0 auto 0 30px;
  font-size: 18px;
  color: #de7f39;
  max-width: 430px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 700;
}

@media screen and (max-width: 812px) {
  .userRole {
    display: none;
  }
  .header {
    padding: 13px 10px;
    padding-right: calc((100vw - 100%) + 10px);
  }

  .logoView {
    display: none;
  }

  .requestRide {
    margin-right: 0;
  }
  .viewDashboardTitle {
    margin-left: 10px;
  }

  .requestRide .requestRideBtn {
    font-size: 12px;
    line-height: 12px;
    padding: 8px 5px;
  }
}

@media screen and (max-width: 450px) {
  .driverHeader {
    width: 370px;
  }

  .viewDashboardTitle {
    margin-left: 10px;
    max-width: 160px;
  }
}
