.ant-rate-star-full .ant-rate-star-second {
    color: black;
  }
  .ant-rate-star-first
  {
    color: black !important; 
  }
   
  .ant-rate-star .ant-rate-star-zero
  {
    color: black !important; 
  }