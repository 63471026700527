.sorting {
  display: flex;
  flex-direction: column;
  font-size: 11px;
}
.sorting:hover {
  opacity: 0.7;
  cursor: pointer;
}
.sorting .highlighted {
  color: #de7f39;
}
