.baseTable :global(.ant-table-thead > tr > th) {
  padding: 16px 8px;
  height: 55px;
  color: #4f4f60;
}
.baseTable.hoverOnRow :global(.ant-table-tbody > tr.ant-table-row:hover) .actionsColumn {
  opacity: 1;
}

.baseTable
  :global(.ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before) {
  display: none;
}

.baseTable :global(.ant-table-tbody > tr > td),
.baseTable :global(.ant-table tfoot > tr > th),
.baseTable :global(.ant-table tfoot > tr > td) {
  padding: 16px 8px;
  color: #4f4f60;
}
.baseTable.hoverOnRow :global(.ant-table-tbody > tr.ant-table-row:hover > td) {
  background: #fff8f2;
  cursor: pointer;
}
.claimedRow,
.unableToFullfillRow {
  background: #fafafa;
}
.newRequestRow {
  background: #fff8f2;
}

:global(.ant-table-tbody) > .claimedRow,
.unableToFullfillRow > :global(td) {
  color: #fff;
  opacity: 0.6;
}
.baseTable :global(.ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container::after),
.baseTable :global(.ant-table-ping-left:not(.ant-table-has-fix-left) .ant-table-container::before) {
  box-shadow: none;
}
