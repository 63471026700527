.pageContent {
  background: #fefdfc;
  border-radius: 10px;
  padding: 40px 16px 0 16px;
  width: 100%;
  max-width: 812px;
  margin: 0 auto;
  min-height: 100vh;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}
.backBtn {
  cursor: pointer;
}
.pageTitle {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 12px 30px 12px 20px;
}
.modalContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 20px;
  margin: 0 auto;
}
.modalDescription {
  text-align: center;
  margin: 0 auto 20px;
}
.modalBtns {
  display: flex;
  justify-content: space-around;
}
.modalBtns .navigationBtn {
  background: #fff;
  color: #de7f39;
  border-color: #de7f39;
}
.comment {
  display: block;
  width: auto;
  margin: 20px 20px 30px;
}

.deadhead {
  display: block;
  width: 90%;
 
}
.tooltipWrapper
{
  align-self: center;
    margin-top: 20px;
    margin-left: 5px;
    color: white;
}

.deadheadWrapper
{
  display: flex; 
 flex-wrap: wrap;  
 margin: 20px 20px 30px;
}

.tooltipContent {
  display: block;
  padding: 4px;
  
}

.tooltipInfo {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #de7f39;
}

@media screen and (min-width: 812px) {
  .modalContent {
    width: auto;
    padding: 0 20px;
  }
}

@media screen and (max-width: 400px) {
  .pageContent {
    width: 350px;
  }
}
