.modalContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 500px;
  margin: 0 auto;
}
.modalDescription {
  text-align: center;
  margin: 0 auto 40px;
}

.disableOrganizationBtns {
  display: flex;
  justify-content: space-between;
  width: 320px;
  margin: 0 auto;
}
.disableOrganizationBtns .navigationBtn {
  background: #fff;
  color: #de7f39;
  border-color: #de7f39;
}
.name {
  font-weight: bold;
}

@media screen and (max-width: 812px) {
  .modalContent {
    width: auto;
    padding: 0 20px;
  }
}
