.driverCard {
  margin: 16px 16px 40px 16px;
  padding: 16px;
  border-radius: 10px;
  box-shadow: 0px 1px 10px rgba(163, 138, 120, 0.2);
  cursor: pointer;
}

.driverCard .actionBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 42px;
  height: 42px;
  margin-left: 14px;
}

.driverCard .copiedBtn {
  background: #fff;
  color: #de7f39;
}

.driverCard .arrow {
  transform: rotate(90deg);
  margin-bottom: 14px;
}

.driverCard .arrowStatus {
  transform: rotate(90deg);
}

.cardTitle {
  display: block;
  color: #ababab;
  margin: 0 0 6px 0;
}

.cardValue {
  width: 100%;
  display: inline-flex;
  align-items: center;
  margin: 0 0 14px 0;
  color: #625b5b;
}

.infoInput {
  width: 40% !important;
  margin: 0px 30px !important;
}

.linkText {
  display: inline-block;
  color: #de7f39;
  font-size: 14px;
  line-height: 22px;
  text-decoration: underline;
  margin: 0 0 38px 144px;
}

.fullInfoCardValue {
  justify-content: space-between;
}

.riderDescriptionTitle {
  margin-top: 10px;
}

.status {
  display: inline-block;
  margin-bottom: 22px;
}

.fullInfoStatus {
  margin: 22px 0;
}

.defaultCardTitle {
  margin: 0;
}

.timezone {
  margin-left: 5px;
}

.riderInfo {
  display: flex;
  align-items: center;
}

.driverCard .phoneBtn {
  padding: 0;
}

.phoneLink {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.addressInfo {
  display: flex;
  margin-bottom: 8px;
}

.separator {
  display: flex;
  align-self: center;
  margin: 0 20px;
  width: 1px;
  height: 16px;
  background: #ababab;
}

.rideId,
.driverComment {
  display: flex;
  margin: 10px 0 0 0;
}

.rideIdValue {
  width: auto;
  margin: 0 0 14px 6px;
}

.driverCardFullInfo {
  box-shadow: none;
  cursor: auto;
}

.driverManagePanelVisible {
  margin-bottom: 80px;
}

@keyframes ring {
  0% {
    -webkit-transform: rotate(-15deg);
    -ms-transform: rotate(-15deg);
    transform: rotate(-15deg);
  }

  2% {
    -webkit-transform: rotate(15deg);
    -ms-transform: rotate(15deg);
    transform: rotate(15deg);
  }

  4% {
    -webkit-transform: rotate(-18deg);
    -ms-transform: rotate(-18deg);
    transform: rotate(-18deg);
  }

  6% {
    -webkit-transform: rotate(18deg);
    -ms-transform: rotate(18deg);
    transform: rotate(18deg);
  }

  8% {
    -webkit-transform: rotate(-22deg);
    -ms-transform: rotate(-22deg);
    transform: rotate(-22deg);
  }

  10% {
    -webkit-transform: rotate(22deg);
    -ms-transform: rotate(22deg);
    transform: rotate(22deg);
  }

  12% {
    -webkit-transform: rotate(-18deg);
    -ms-transform: rotate(-18deg);
    transform: rotate(-18deg);
  }

  14% {
    -webkit-transform: rotate(18deg);
    -ms-transform: rotate(18deg);
    transform: rotate(18deg);
  }

  16% {
    -webkit-transform: rotate(-12deg);
    -ms-transform: rotate(-12deg);
    transform: rotate(-12deg);
  }

  18% {
    -webkit-transform: rotate(12deg);
    -ms-transform: rotate(12deg);
    transform: rotate(12deg);
  }

  20% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

.driverCard .phoneIcon {
  animation: ring 2s ease infinite;
  -webkit-animation: ring 2s ease infinite;
}