.container {
  display: flex;
  flex-wrap: wrap; /* Allows the columns to wrap when there's not enough space */
}

.notificationItem {
  margin-right: 0 4px;
}

.notificationList {
  display: flex;
}

.column1 {
  flex-basis: 65%; /* Each column occupies 50% of the container width initially */
}

.column2 {
  flex-basis: 33%;
}

.rideHistory {
  margin-bottom: 32px;
  text-align: center;
}

:global(.ant-timeline-item-label) {
  width: calc(40% - 12px) !important;
}
:global(.ant-timeline-item-head){
  inset-inline-start: 40% !important;
}
:global(.ant-timeline-item-tail) {
  inset-inline-start: 40% !important;
}
:global(.ant-timeline-item-content) {
  inset-inline-start: calc(40% - 4px) !important;
  width: calc(60% - 12px) !important;
}

.detailFont {
  font-size: 12px !important;
}

.divider {
  height: 348px;
}

.singleLine {
  display:list-item;
}

/* Optional: Add media queries for better control on responsiveness */

@media screen and (max-width: 600px) {
  .column1 {
    flex-basis: 100%; /* Each column occupies 100% of the container width on small screens */
  }

  .column2 {
    flex-basis: 100%;
  }
}

@media screen and (max-width: 1342px) {
  .column1 {
    flex-basis: 100%; /* Each column occupies 100% of the container width on small screens */
  }

  .column2 {
    /* flex-basis: 100%;*/
    min-width: 300px;
  }

  .divider {
    display: none;
  }
}
.rideDetails {
  width: 60%;
  color: #4f4f4f;
  margin-bottom: 10px;
}

.headTitle {
  margin-bottom: 50px;
}

.summary {
  font-size: 14px;
  margin-bottom: 60px;
}

.driverLabelText {
  margin-right: 8px;
  color: #ababab;
}
.tooltipWrapper {
  width: 24px;
  margin-top: 0px;
}
.tooltipInfo {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #de7f39;
  color: white;
  margin-top: -5px;
}
.tooltipContent {
  padding: 4px;
}

.rideActionPointer {
  cursor: pointer;
  color: blue;
  text-decoration: underline;
}

:global(.ant-tooltip .ant-tooltip-inner) {
  .tooltipContent {
    padding: 0;
  }
}

.tooltipDisabled {
  background: #d9d9d9;
}
.driverLabelContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-bottom: 5px;
}

.record {
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}

.riderNameGap {
  margin: 5px 0;
}

.recordTitle {
  font-size: 14px;
  color: #ababab;
  margin-right: 3px;
}

.recordSection,
.seperateSection,
.recordDescription,
.secondaryPhone,
.ridersPreferredLanguage {
  margin: 10px 0;
}

.homeAddress {
  margin: 14px 0 0;
}

@keyframes ring {
  0% {
    -webkit-transform: rotate(-15deg);
    -ms-transform: rotate(-15deg);
    transform: rotate(-15deg);
  }

  2% {
    -webkit-transform: rotate(15deg);
    -ms-transform: rotate(15deg);
    transform: rotate(15deg);
  }

  4% {
    -webkit-transform: rotate(-18deg);
    -ms-transform: rotate(-18deg);
    transform: rotate(-18deg);
  }

  6% {
    -webkit-transform: rotate(18deg);
    -ms-transform: rotate(18deg);
    transform: rotate(18deg);
  }

  8% {
    -webkit-transform: rotate(-22deg);
    -ms-transform: rotate(-22deg);
    transform: rotate(-22deg);
  }

  10% {
    -webkit-transform: rotate(22deg);
    -ms-transform: rotate(22deg);
    transform: rotate(22deg);
  }

  12% {
    -webkit-transform: rotate(-18deg);
    -ms-transform: rotate(-18deg);
    transform: rotate(-18deg);
  }

  14% {
    -webkit-transform: rotate(18deg);
    -ms-transform: rotate(18deg);
    transform: rotate(18deg);
  }

  16% {
    -webkit-transform: rotate(-12deg);
    -ms-transform: rotate(-12deg);
    transform: rotate(-12deg);
  }

  18% {
    -webkit-transform: rotate(12deg);
    -ms-transform: rotate(12deg);
    transform: rotate(12deg);
  }

  20% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

.phoneNumber .phoneIcon,
.phoneLink .phoneIcon {
  margin-left: 3px;
  color: #ababab;
  animation: ring 2s ease infinite;
  -webkit-animation: ring 2s ease infinite;
}
.d_flex
{
  display: flex;
}
.mt5
{
  margin-top: 5px;
}

.mb10
{
  margin-bottom: 10px;
}

.driverMatched .phoneIcon {
  color: inherit;
}
.roundTripHeader
{
  font-size: 1em;
  margin-top: 10px;
}

.driverMatched {
  margin: 10px 0;
}

.routes {
  display: flex;
  margin: 10px 0 30px 0;
}

.destionationRouteInfo {
  display: flex;
  align-self: flex-start;
}

.vehicleType {
  margin-bottom: 12px;
}

.phoneNumber {
  font-size: 14px;
}

.caregiverInfo {
  margin: 20px 0;
}

.caregiverEmail {
  margin-bottom: 20px;
  color: inherit;
}

.phoneLink,
.driverPhone {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: inherit;
}

.driverPhone {
  display: inline-flex;
  width: auto;
  height: auto;
  justify-content: flex-start;
}

.phoneLink:hover,
.caregiverEmail:hover {
  color: inherit;
  opacity: 0.7;
}

.buttonPanel {
  margin-top: 16px;
}

.otherReason {
  margin: 20px 0 0;
}

.addressInfo {
  display: flex;
  margin-bottom: 20px;
}

.rideCard {
  border-radius: 10px;
  border: 1px solid #e0e0e0;
  padding: 12px;
  min-width: 300px;
}

.riderOptions {
  margin: 5px 5px 5px 0;
}

.additionalInfo {
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.originAddress {
  font-size: 14px;
  color: #ababab;
  margin: 0 3px 2px 0;
}

.ridersInfo,
.genderInfo {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 14px;
}

.genderInfo {
  color: inherit;
  margin: 14px 0;
}

.riderInformation :last-child {
  margin-bottom: 0;
}

.additionalOptions {
  list-style: none;
  margin: 0;
  padding: 0 20px;
}

.additionalOptions li {
  margin-bottom: 4px;
}

.additionalOptions li::before {
  content: "";
  display: inline-block;
  background: #e0e0e0;
  border-radius: 50%;
  width: 5px;
  height: 5px;
  margin: 0 8px 2px 0;
}

.arrows {
  width: 97px;
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.separator {
  display: flex;
  align-self: center;
  margin: 0 20px;
  width: 1px;
  height: 16px;
  background: #ababab;
}
.mtb10
{
  margin-top: 10px;
  margin-bottom: 10px;
}
.m5
{
  margin: 5px;
}
.d_contnent
{
 display: contents;
  
}

.arrowLeft {
  transform: rotate(180deg);
}

.sendBtn {
  margin-left: 30px;
}

.noDriver {
  font-weight: bold;
}

.statusBtns {
  display: flex;
  margin: 14px 0;
}

.status {
  margin-top: 30px;
}

.statusFullInfo {
  margin: 12px 0 0;
}

.canceled,
.expired {
  color: #ff0052 !important;
}

.cancellationInfoContainer {
  margin: 30px 0 15px;
}

.startDateInfo {
  display: flex;
  align-items: center;
  margin: 30px 0 10px;
}

.startDateText {
  margin-right: 20px;
}

.cancelation {
  margin: 4px 20px 4px 0;
}

.riderInformation {
  flex-shrink: 1;
}

.startDateInfo .editTimeBtn {
  display: flex;
  align-items: center;
  height: 24px;
  padding: 1px 8px;
  line-height: 22px;
  font-size: 14px;
  font-weight: normal;
  border-radius: 4px;
}

.link {
  color: #de7f39;
  margin-right: 5px;
}

.link:hover {
  opacity: 0.7;
  color: #de7f39;
}

.createdAt {
  margin: 20px 0 15px 0;
}

.rideDetailsHead {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.recurringRideDetails {
  width: 240px;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin: 15px 0 0 15px;
}

.recurringRideDetailsHead {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 12px;
}

.scheduledRecurringRideIcon > svg {
  width: 21px;
  height: 17px;
  color: #de7f39;
}

.scheduledRecurringRideIcon {
  padding-right: 10px;
}

.recurringRideDetailsTitle {
  color: #de7f39;
  font-size: 14px;
  line-height: 22px;
}

.recurringRideDetailsDecription {
  color: #4f4f60;
  font-size: 14px;
  line-height: 22px;
}

.driverComment {
  margin-top: 10px;
}

.latestSnackbar {
  margin-top: 20px;
  width: 860px;
  display: flex;
}

.otherLeg {
  margin-bottom: 50px;
}

.caregiver {
  margin: 30px 0;
}

.phoneOptions {
  margin: 14px 0;
  padding: 0;
}

.vehicleInfo {
  margin-top: 12px;
}
.rideStatusInfoSpace {
  margin-top: 30px;
}

.statusBtns .arrivalBtn {
  background: #1cb200 !important;
  border-color: #1cb200 !important;
}

.statusBtns .arrivalBtn:hover,
.statusBtns .arrivalBtn:active,
.statusBtns .arrivalBtn:focus {
  background: #1cb200 !important;
  border-color: #1cb200 !important;
}

@media screen and (max-width: 1075px) {
  .form {
    width: 100%;
  }

  .rideCard {
    min-width: auto;
  }

  .recurringRideDetails {
    order: 1;
    margin-bottom: 20px;
  }

  .riderInformation {
    order: 2;
  }

  .rideDetailsHead {
    flex-direction: column;
    width: fit-content;
  }

  .recurringRideDetailsHead {
    margin-bottom: 4px;
  }

  .latestSnackbar {
    width: auto;
  }
}

@media screen and (max-width: 575px) {
  .arrows {
    width: 35px;
  }

  .latestSnackbar {
    width: auto;
  }
}

.mtb5 {
  margin: 5px 0;
}
