.customPopOver {
  max-height: 400px;
  overflow-y: unset;
  overflow-x: unset;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);

}

.popOver.overlay {
  width: 280px;
}

.popOver.overlay :global(.ant-popover-inner > .ant-popover-inner-content) {
  padding: 0;
}
.popOver.overlay.dateRangeVisible {
  visibility: hidden;
  height: 0;
  padding: 0;
}
.filterRow {
  display: flex;
  align-items: center;
}
.filter {
  margin-right: 6px;
  overflow-y: auto;
}
