body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}
img,
video,
canvas,
svg,
object,
iframe {
  box-sizing: content-box;
  vertical-align: middle;
  border-style: none;
}

.grecaptcha-badge {
  visibility: hidden;
}

.required:after {
  content: ' *';
  color: red;
}