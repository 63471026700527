.iframe_wrapper {
    height: 500px;
    position: relative;
    overflow: hidden;
}

.iframe {
    width: 700px;
    height: 600px;
    position: absolute;
    top: -160px;
    overflow: hidden;
}

@media (max-width: 400px) {
    .iframe {
        width: 300px;
        height: 450px;
        position: absolute;
        top: -100px;
    }
}