.twoFactorModal {
  display: flex;
}

.modalContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 480px;
  margin: 0 auto;
}
.modalDescription {
  text-align: center;
  margin: 0 auto 30px;
}
.modalBtns {
  display: flex;
  justify-content: space-around;
  width: 350px;
  margin: 30px auto 0;
}
.modalBtns .navigationBtn {
  background: #fff;
  color: #de7f39;
  border-color: #de7f39;
}
.optionName {
  font-weight: bold;
}
.inputLabel {
  display: block;
  width: 300px;
  margin: 0 auto;
}

.twofactorModal .mailIcon {
  font-size: 100px;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  color: #de7f39;
}

@media screen and (max-width: 812px) {
  .modalContent {
    width: auto;
    padding: 0 20px;
  }
}
