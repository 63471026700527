.pageContent {
  background: #fff;
  border-radius: 10px;
  padding: 40px 20px 0 20px;
}
.header {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}
.backBtn {
  cursor: pointer;
}
.pageTitle {
  margin: 0 0 0 30px;
  color: #333;
  font-size: 24px;
  line-height: 24px;
}
.form {
  width: 650px;
  margin-top: 20px;
}

.btnPanel {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 60px 0 20px 0;
}

.btnControl {
  margin-bottom: 20px;
}

.btnPanel .navigationBtn {
  background: #fff;
  color: #de7f39;
  border-color: #de7f39;
}

.modalContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 460px;
  margin: 0 auto;
}
.modalDescription {
  text-align: center;
  margin: 0 auto 50px;
}
.modalBtns {
  width: 200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
}
.modalBtns .navigationBtn {
  background: #fff;
  color: #de7f39;
  border-color: #de7f39;
}
.errorMessage {
  color: #f5222d;
  font-size: 12px;
  line-height: 22px;
}

.nameRow {
  display: flex;
}

.name {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.nameLabel {
  display: block;
  width: 290px;
  margin-bottom: 20px;
}
.required:after {
  content: ' *';
  color: red;
}
.emailContainer {
  display: flex;
  flex-wrap: wrap;
}

.email {
  display: block;
  width: 290px;
  margin: 0 30px 20px 0;
}

.checkboxesColumn {
  display: inline-flex;
  flex-direction: column;
  margin-top: 60px;
}
.rideRequestFieldsHead {
  display: block;
  margin-bottom: 14px;
  font-weight: bold;
}
.checkboxesList {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.checkbox {
  margin-bottom: 16px;
  display: inline-flex;
  cursor: pointer;
}
.additionalOptions .checkbox {
  width: 275px;
}
.checkboxLabel {
  margin-left: 8px;
}

.additionalOptions {
  display: block;
  margin-top: 40px;
}
.additionalOptionsHead {
  display: block;
  margin-bottom: 14px;
  font-weight: bold;
}
.checkboxesRow {
  display: flex;
  flex-wrap: wrap;
}

.personalInfoLabel {
  display: block;
  width: 290px;
  margin-bottom: 20px;
}
.customPhone {
  display: block;
  width: 290px;
}

.personalInfo {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media screen and (max-width: 975px) {
  .form {
    width: 100%;
  }
}
