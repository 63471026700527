.dashboard {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 100vh;
}
.main {
  display: flex;
  flex-grow: 1;
  background: #fefbf9;
  overflow-y: hidden;
}
.layout:global(.ant-layout.ant-layout-has-sider) {
  min-height: 100vh;
  flex-direction: column;
}
.pageContent {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 20px 40px 0 40px;
  transition: all 0.2s;
  margin-left: 330px;
  margin-top: 70px;
  overflow: auto;
}
.driverContent {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 70px;
}
:global(.ant-layout-sider-collapsed) ~ .pageContent {
  margin-left: 174px;
}

@media screen and (max-width: 812px) {
  .pageContent {
    margin-left: 200px;
    padding: 10px;
    /* height: calc(100vh - px); */
    /* height: auto; */
  }
  :global(.ant-layout-sider-collapsed) ~ .pageContent {
    margin-left: 0;
  }
}
