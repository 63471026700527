.pageContent {
  background: #fff;
  border-radius: 10px;
  padding: 40px 37px;
}

.pageTitle {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 12px 30px 12px 20px;
}
.title {
  font-size: 24px;
  line-height: 24px;
  margin: 12px 40px 12px 0;
}
.createRide {
  background: #fff;
  border-radius: 10px;
  padding: 40px 37px;
}
.header {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}
.backBtn {
  cursor: pointer;
}
.rideDetails {
  margin-left: 23px;
}
