.checkboxLabel {
  margin-left: 8px;
}
.headTitle {
  margin: 0 0 50px 0;
  font-size: 24px;
}

.form {
  width: 630px;
}

.vehicleType {
  display: block;

  height: 84px;
  width: 100%;
}

.vehicleTypeLabel {
  display: block;
  margin: 20px 0px 40px;
}
.stairsLabel {
  display: block;
  margin-top: 20px;
}
.additionalOptions {
  display: block;
  margin-top: 40px;
}
.additionalOptionsHead {
  display: block;
  margin-bottom: 14px;
  font-weight: bold;
}
.checkboxesRow {
  display: flex;
  flex-wrap: wrap;
}

.checkbox {
  margin-bottom: 16px;
  width: 275px;
  cursor: pointer;
}

.required:after {
  content: ' *';
  color: red;
}
.snackbarField {
  margin-bottom: 40px;
}
.measurement {
  display: flex;
  margin: 20px 0;
  width: 380px;
  justify-content: space-between;
}

.numberField {
  display: block;
  width: 120px;
}
@media screen and (max-width: 975px) {
  .form {
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .vehicleType {
    width: 300px;
  }
}
