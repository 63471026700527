.xl {
  width: 350px;
  height: 102px;
}
.lg {
  width: 251px;
  height: 72px;
  margin-top: 110px;
}
.md {
  width: 130px;
  height: 44px;
}
.sm {
  width: 50px;
  height: 18px;
}

@media screen and (max-width: 812px) {
  .lg,
  .xl {
    width: 210px;
    height: 56px;
    margin-top: 60px;
  }
  .md {
    width: 110px;
    height: 28px;
  }
}
