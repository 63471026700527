.pending,
.request,
.unableToFullfill {
  color: #828282;
}
.completed {
  color: #219653;
}
.canceled,
.expired {
  color: #eb5757;
}
.accepted,
.inProgress {
  color: #2f80ed;
}
