.addressBookList
{
  width: 500px;
}

.closeIcon {
  font-size: 30px !important; 
  padding-left: 4px !important;
  cursor: pointer;
}

.editIcon
{
  margin-left: 3px;
  margin-top: 2px;
  position: relative;
  font-size: 18px;
}
 
.ant-list-item-action
{
  padding: 5px !important;
}

  .ant-list-item:hover {
    background-color: #3a1919; /* Change the background color on hover */
    cursor: pointer; /* Change the cursor to pointer on hover */
  
}
.hoverable_list_item
{
  padding: 12px;
}
.hoverable_list_item:hover {
  background-color: #dd8136d2 !important; /* Change the background color on hover */
  cursor: pointer; /* Change the cursor to pointer on hover */
}

.addressContanier
{
  
  padding-left: 10px;
  display: contents;

}
.linkbutton
{
  width: auto !important;
    height: auto;
    padding: 0px;
    margin-left: 10px;
    margin-top: 0px;
    position: absolute;
    right: 35px;
    top: 12px;
}
.editText
{
  display: inline;
    position: absolute;
    right: 20px;
    /* text-decoration: underline; */
    top: 4px;
}
  
 .navigationBtn 
 {
  display: flex;
  flex-wrap: wrap;
  width: 100px;
  
  max-width: 750px;
  justify-content: center;
  
  background: #de7f39;
  color:#fff; 
  border-color: #de7f39;
}
.formatted_Address
{
  margin-left: 20px;
  margin-right: 10px;
}
.ant-list-item-action {
   
  margin-inline-start: 0 px !important;  
  
}

