.table :global(.ant-table-tbody > tr.ant-table-row:hover) .actionsColumn {
  opacity: 1;
}

.actionsColumn {
  opacity: 0;
  transition: all 0.4s;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.actionsColumn .actionsIcon {
  color: #de7f39;
  font-size: 22px;
  display: inline-block;
}
.actionsColumn .actionsIcon:first-child {
  margin-right: 15px;
}
.actionsColumn .actionsIcon:hover {
  opacity: 0.7;
}

.timezone {
  margin-left: 5px;
}
.dflex
{
  display: flex;
}

.draftIdCell,
.draftIdCellValue {
  max-width: 110px;
  width: 110px;
}

.riderCell,
.riderCellValue {
  width: 130px;
  max-width: 130px;
}

.vehicleCell,
.vehicleCellValue {
  max-width: 250px;
  width: 250px;
}

.rideOptionsCell,
.rideOptionCellValue {
  max-width: 210px;
  width: 210px;
}

.pickUpDateCell,
.pickUpDateCellValue {
  max-width: 210px;
  width: 210px;
}

.driverCell,
.driverCellValue {
  max-width: 110px;
  width: 110px;
}

.rideOptionsCell,
.rideOptionsCellValue {
  max-width: 170px;
  width: 180px;
}
.statusCell,
.statusCellValue {
  width: 120px;
  max-width: 120px;
}

.driverNotAssigned {
  color: red;
}
.actualWeight {
  color: #eb5757;
}