.btns {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 750px;
  justify-content: space-between;
  margin-top: 60px;
}
.btnsSteps {
  display: flex;
}
.btnsPrev {
  margin-right: 20px;
}

.btns .navigationBtn {
  background: #fff;
  color: #de7f39;
  border-color: #de7f39;
}

@media screen and (max-width: 975px) {
  .form {
    width: 100%;
  }
  .btns .formButton {
    padding: 6px 10px;
    font-size: 14px;
  }
}
