.pageContent {
  background: #fefdfc;
  border-radius: 10px;
  padding: 40px 12px 0 12px;
  width: 100%;
  max-width: 812px;
  margin: 0 auto;
  min-height: 100vh;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}

.pageSection:after {
  content: '';
  display: block;
  height: 1px;
  background: #f2f2f2;
  margin: 80px 0;
}
.pageTitle {
  margin: 0 0 0 18px;
  color: #333;
  font-size: 20px;
  line-height: 24px;
}
.form {
  width: 100%;
}

.btnPanel {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 60px 0 20px 0;
}

.btnPanel .navigationBtn {
  background: #fff;
  color: #de7f39;
  border-color: #de7f39;
}
.backBtn {
  cursor: pointer;
}

@media screen and (max-width: 400px) {
  .pageContent {
    width: 370px;
  }
}
